import { START_LOADING_COMPANY_SIGNUP_ENDPOINT, END_LOADING_COMPANY_SIGNUP_ENDPOINT, POST_COMPANY_SIGNUP_ENDPOINT_DATA, FETCH_COMPANY_SIGNUP_ENDPOINT_DATA, CLEAR_COMPANY_SIGNUP_ENDPOINT_DATA } from '../constants/actionTypes';

export default (state = { isLoading: true, companySignupEndpoints: [] }, action) => {
    switch (action.type) {
        case START_LOADING_COMPANY_SIGNUP_ENDPOINT:   
            return { ...state, isLoading: true };
        case END_LOADING_COMPANY_SIGNUP_ENDPOINT:
            return { ...state, isLoading: false };;
        case POST_COMPANY_SIGNUP_ENDPOINT_DATA:
            return { ...state, companySignupEndpoints: action.payload };
        case FETCH_COMPANY_SIGNUP_ENDPOINT_DATA:
            return { ...state, companySignupEndpoints: action.payload };
        case CLEAR_COMPANY_SIGNUP_ENDPOINT_DATA:
            return { ...state, companySignupEndpoints: []}
        default:
            return state;
    }
};