import { Box, Typography, IconButton, Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import Controls from '../../PageProps/Controls/Controls';
// import { useForm, Form } from '../../PageProps/useForm';
// import useAuth from '../../../hooks/useAuth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { LOGOUT } from '../../../../constants/actionTypes'; 
import Controls from '../../../PageProps/Controls/Controls';
import { useForm, Form } from '../../../PageProps/useForm';
import useAuth from '../../../../hooks/useAuth';

import LogoutIcon from '@mui/icons-material/Logout';
import { getUserDetails, completeSignupFullCompany } from '../../../../actions/userDetails';
import { signupAuth } from '../../../../actions/authData';

const initialFValues = {


    agreeTerms: false,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  


const SURedirect = () => {
    // Dispatch to get username, first and last name
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { setAuth } = useAuth({});

    const { isLoading: isUDLoading, userDetails } = useSelector((state) => state.userDetails);
    const { isLoading, authData } = useSelector((state) => state.authData);

    // console.log(authData)


    // let jwtSignup

    useEffect(() => {
      dispatch(signupAuth({ auth: params.id }))
    },[])



    useEffect(() => {
      if(authData?.message === "Not valid or expired token") {
        // console.log("Triggered Cond")
        navigate(authData?.targetLocation)
      } else if  (authData?.message === "Successfully authenticated") {
        setAuth(authData?.authDetails)       
        navigate(authData?.authDetails?.targetLocation)
      }
    },[authData])


    return (
    <Box display='flex' width='100%' height='100%' justifyContent={'center'} alignContent='center' alignItems={'center'} justifyItems='center'>
        
        <Box  width='50%' height='70%' bgcolor='#f2f0f0' boxShadow={3} justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'  p={5}>
        <Box >
                <Typography mb={2} variant='h2'>Welcome</Typography>
            </Box>
            <Box>
                <Typography  mb={2}>Let's finalize your setup!</Typography>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                <Typography  mb={2} flexBasis='100%'>Before we start we just need to fetch a few details. This shouldn't take long, but could take upto 20 seconds.</Typography>
            </Box>
            {
                authData?.message === 'Expired token and user not found' ? 
                <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                    <Typography variant='h3'>The link you used appears to be invalid or completely expired. Please ask your company admin to resend your invitation link.</Typography>
                </Box>
                :
                authData?.message === 'Expired token and failed to save new' ?
                <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                    <Typography> variant='h3'The link has expired and for some reason the attempt to email a new code failed. Please get your company admin to recheck your email is correct and resend an invite link.</Typography>
                </Box>

                :
                authData?.message === 'Expired token and new sent' ? 
                    <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                        <Typography variant='h3'>The link you used has expired. A new, valid link should have been emailed to your email address. Please follow the link in that email to complete your signup.</Typography>
                    </Box>
                :

                authData?.message === 'Expired token and failed resend' ? 
                
                <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                    <Typography variant='h3'>The link has expired and for some reason the attempt to email a new code failed. Please get your company admin to recheck your email is correct and resend an invite link.</Typography>
                </Box>

                :

                authData?.message === 'Login Failure' ? 
                
                <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                    <Typography variant='h3'>An error occured, please try the link in your email address. If the problem persists please get you company admin to resend the invite code.</Typography>
                </Box>

                :
                <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                    <CircularProgress />
                </Box>
                
                

                

            }
            
        </Box>
        
    </Box>
  )
}


export default SURedirect