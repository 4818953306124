import { START_LOADING_EMPLOYEES_ENDPOINT, END_LOADING_EMPLOYEES_ENDPOINT, POST_EMPLOYEES_ENDPOINT_DATA, FETCH_EMPLOYEES_ENDPOINT_DATA, CLEAR_EMPLOYEES_ENDPOINT_DATA } from '../constants/actionTypes';

export default (state = { isLoading: true, employeesDetailsEndpoints: [] }, action) => {
    switch (action.type) {
        case START_LOADING_EMPLOYEES_ENDPOINT:   
            return { ...state, isLoading: true };
        case END_LOADING_EMPLOYEES_ENDPOINT:
            return { ...state, isLoading: false };;
        case POST_EMPLOYEES_ENDPOINT_DATA:
            return { ...state, employeesDetailsEndpoints: action.payload };
        case FETCH_EMPLOYEES_ENDPOINT_DATA:
            return { ...state, employeesDetailsEndpoints: action.payload };
        case CLEAR_EMPLOYEES_ENDPOINT_DATA:
            return { ...state, employeesDetailsEndpoints: []}
        default:
            return state;
    }
};