import { CircularProgress } from '@mui/material'
import React, { Suspense } from 'react'

const LoadManagerUI = () => {
    const OtherComponent = React.lazy(() => import('./ManagerUI'));

  return (
    <Suspense fallback={<CircularProgress />}>
        <OtherComponent />
    </Suspense>
  )
}

export default LoadManagerUI