import { START_MESSAGE_LOADING, END_MESSAGE_LOADING, FETCH_ALL_MESSAGES, SEND_MESSAGE, FETCH_MESSAGE_INBOX } from '../constants/actionTypes';

export default (state = {isLoading: true, messages: []}, action) => {
    switch (action.type) {
        case START_MESSAGE_LOADING:
            return { ...state, isLoading: true };
        case END_MESSAGE_LOADING:
            return { ...state, isLoading: false };
        case FETCH_ALL_MESSAGES:
            // console.log("Got to get messages reducer");
            return { ...state, messages: action.payload };
        case FETCH_MESSAGE_INBOX:
            // console.log("Got to get inbox reducer");
            return { ...state, messages: action.payload };
        case SEND_MESSAGE:
            // console.log("Got to send messages reducer");
            return {...state, messages: action.payload}
        default:
            return state;
    }
}