
import { START_TICKET_DETAILS_LOADING, END_TICKET_DETAILS_LOADING, GET_TICKET_DETAILS, UPDATE_TICKET_DETAILS, CLEAR_TICKET_DETAILS} from '../constants/actionTypes';

export default (state = { isLoading: true, ticketDetails: [] }, action) => {
    switch (action.type) {
        case START_TICKET_DETAILS_LOADING:
            return { ...state, isLoading: true };
        case END_TICKET_DETAILS_LOADING:
            return { ...state, isLoading: false };
        case GET_TICKET_DETAILS:
            return { ...state, ticketDetails: action.payload };
        case UPDATE_TICKET_DETAILS:
            return {...state, ticketDetails: action.payload}
        case CLEAR_TICKET_DETAILS:
            return {...state, ticketDetails: []}
        default:
            return state;
    }
};