import { START_PERSONAL_VEHICLES_LOADING, END_PERSONAL_VEHICLES_LOADING, FETCH_PERSONAL_VEHICLES, CLEAR_PERSONAL_VEHICLES, ADD_PERSONAL_VEHICLE, FETCH_PERSONAL_COMPANY_VEHICLES, ADD_PERSONAL_COMPANY_VEHICLE } from '../constants/actionTypes';


export default (state = {isLoading: true , personalVehicles: []}, action) => {
    switch (action.type) {
        case  START_PERSONAL_VEHICLES_LOADING:
            return { ...state, isLoading: true };
        case END_PERSONAL_VEHICLES_LOADING:
            return { ...state, isLoading: false };
        case FETCH_PERSONAL_VEHICLES:
            return { ...state, personalVehicles: action.payload };
        case FETCH_PERSONAL_COMPANY_VEHICLES:
            return { ...state, personalVehicles: action.payload };
        case ADD_PERSONAL_VEHICLE:
            return { ...state, personalVehicles: action.payload };
        case ADD_PERSONAL_COMPANY_VEHICLE:
            return { ...state, personalVehicles: action.payload };
        case CLEAR_PERSONAL_VEHICLES:
            return { ...state, personalVehicles: null }
        default:
            return state;
    }
}