import { 
    START_LOADING_MANAGER_DETAILS_ENDPOINT, 
    END_LOADING_MANAGER_DETAILS_ENDPOINT, 
    POST_MANAGER_DETAILS_ENDPOINT_DATA, 
    FETCH_MANAGER_DETAILS_ENDPOINT_DATA, 
    CLEAR_MANAGER_DETAILS_ENDPOINT_DATA 
} from '../constants/actionTypes';

export default (state = { isLoading: true, managerDetailsEndpoints: [] }, action) => {
    switch (action.type) {
        case START_LOADING_MANAGER_DETAILS_ENDPOINT:   
            return { ...state, isLoading: true };
        case END_LOADING_MANAGER_DETAILS_ENDPOINT:
            return { ...state, isLoading: false };;
        case POST_MANAGER_DETAILS_ENDPOINT_DATA:
            return { ...state, managerDetailsEndpoints: action.payload };
        case FETCH_MANAGER_DETAILS_ENDPOINT_DATA:
            return { ...state, managerDetailsEndpoints: action.payload };
        case CLEAR_MANAGER_DETAILS_ENDPOINT_DATA:
            return { ...state, managerDetailsEndpoints: []}
        default:
            return state;
    }
};