import { START_TOKEN_LOADING, END_TOKEN_LOADING, FETCH_TOKEN_AMOUNTS } from '../constants/actionTypes';


export default (state = { isLoading: true, getTokens: [] }, action) => {
    switch (action.type) {
        case START_TOKEN_LOADING:
            return { ...state, isLoading: true };
        case END_TOKEN_LOADING:
            return { ...state, isLoading: false };
        case FETCH_TOKEN_AMOUNTS:
            return { ...state, getTokens: action.payload}
        default:
            return state;
    }
};
