export default (state = {isLoading: true, companyHouse: []}, action) => {
    switch (action.type) {
        case 'START_COMPANY_HOUSE_LOADING':
            return { ...state, isLoading: true };
        case 'END_COMPANY_HOUSE_LOADING':
            return { ...state, isLoading: false };
        case 'FETCH_COMPANY_HOUSE_DETAILS':
            return { ...state, companyHouse: action?.payload};
        default:
            return state;
    }
}



