
import { START_DIRECT_MESSAGE_LOADING, END_DIRECT_MESSAGE_LOADING, SEND_DIRECT_MESSAGE, CLEAR_DIRECT_MESSAGE } from '../constants/actionTypes';

export default (state = { isLoading: true, sendMessages: [] }, action) => {
    switch (action.type) {
        case START_DIRECT_MESSAGE_LOADING:
            return { ...state, isLoading: true };
        case END_DIRECT_MESSAGE_LOADING:
            return { ...state, isLoading: false };
        case SEND_DIRECT_MESSAGE:
            return { ...state, sendMessages: action.payload };
        case CLEAR_DIRECT_MESSAGE:
            return {...state, sendMessages: null}
        default:
            return state;
    }
};