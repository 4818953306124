import { START_USER_DETAILS_LOADING, END_USER_DETAILS_LOADING, GET_USER_DETAILS, UPDATE_USER_DETAILS, UPDATE_COSTCENTRE_PERMS_DETAILS, GET_USER_LICENCE_DETAILS, CLEAR_USER_DETAILS, FIRST_LICENCE_CHECK, MANUAL_LICENCE_CHECK,
    WITHDRAW_LICENCE_RECHECK, RENEW_LICENCE_RECHECK, COMPLETE_FULL_COMPANY_SIGNUP } from '../constants/actionTypes';

export default (state = { isLoading: true, userDetails: [] }, action) => {
    switch (action.type) {
        case START_USER_DETAILS_LOADING:
            return { ...state, isLoading: true };
        case END_USER_DETAILS_LOADING:
            return { ...state, isLoading: false };
        case GET_USER_DETAILS:
            return { ...state, userDetails: action.payload };
        case UPDATE_USER_DETAILS:
            return { ...state, userDetails: action.payload };
        case UPDATE_COSTCENTRE_PERMS_DETAILS:
            return {...state, userDetails: action.payload};
        case GET_USER_LICENCE_DETAILS:
            return { ...state, userDetails: action.payload};
        case FIRST_LICENCE_CHECK:
            return { ...state, userDetails: action.payload};
        case MANUAL_LICENCE_CHECK:
            return { ...state, userDetails: action.payload };
        case WITHDRAW_LICENCE_RECHECK:
            return { ...state, userDetails: action.payload};
        case RENEW_LICENCE_RECHECK:
            return { ...state, userDetails: action.payload};
        case COMPLETE_FULL_COMPANY_SIGNUP:
                return { ...state, userDetails: action.payload }
        case CLEAR_USER_DETAILS:
            return { ...state, userDetails: null }
        default:
            return state;
    }
};