import { CREATE_COMPANY, DEACTIVATE, INVITE_NEW_COMPANY, DELETE_COMPANY, FETCH_COMPANY, FETCH_ALL_COMPANIES, GET_COMPANY_COST_CENTRES, FORCE_ADD_COMPANY, FETCH_COMPANIES_AND_CRS, START_COMPANIES_LOADING, END_COMPANIES_LOADING, CLEAR_COMPANIES } from '../constants/actionTypes';

export default (state = { isLoading: true, companies: [] }, action) => {
    switch (action.type) {
        case START_COMPANIES_LOADING:
            return { ...state, isLoading: true };
        case END_COMPANIES_LOADING:
            return { ...state, isLoading: false };;
        case FETCH_ALL_COMPANIES:
            // console.log("GOT TO REDUCERS");
            // console.log(action.payload)
            return { ...state, companies: action.payload };
        case FETCH_COMPANIES_AND_CRS:
            // console.log("Got to reducers");
            return { ...state, companies: action.payload };
        case FETCH_COMPANY:
            return { ...state, companies: action.payload };
        case CREATE_COMPANY:
            // console.log("Got to reducers");
            return { ...state, companies: action.payload };
        case GET_COMPANY_COST_CENTRES:
            // console.log("Got to reducers");
            return { ...state, companies: action.payload };
        case FORCE_ADD_COMPANY:
            return { ...state, companies: action.payload };
        case INVITE_NEW_COMPANY:
            return {...state, companies: action.payload };
        case DELETE_COMPANY:
            return { ...state, companies: action.payload };
        case CLEAR_COMPANIES:
            return {...state, companies: []};
        default:
            return state;
    }
};
