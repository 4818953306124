import { Box, Grid, Typography, InputAdornment, IconButton, Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ButtonR, ButtonRP } from '../LandingPage/Button/ButtonElements'
import { ArrowForward, ArrowRight } from '../LandingPage/GettingStarted/gettingStartedElements' 
import Controls from '../../PageProps/Controls/Controls'
import { Form, useForm } from '../../PageProps/useForm'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

// Dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CookiePolicy, PrivacyNotice, TermsAnddConditions } from '../LandingPage/Footer/FooterModals';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCompany } from '../../../actions/companySignupEndpoints';
import { useNavigate } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} sx={{ zIndex: 2500 }} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
}));

const initialFValues = {
    // Company details
    companyFullName: 'Test New Company',
    companyDotString: 'Test Trading Name',
    tradingName: 'TestDotString',
    companyHouse: '123456',
    estimatedVehicles: 44,
    estimatedEmployees: 44,
    contactName: 'Person',

    // Company Address
    buildingNumber: '4',
    streetName: 'street name',
    location: 'town',
    postcode: 'pr27dz',

    // Company Contact
    companyEmail: 'waynethomson5@gmail.com',
    notificationEmail: 'waynethomson5@gmail.com',
    companyPhone: '1234',

    // Admin Account
    adminUsername: 'adminUsername',
    adminPassword: 'Password123!',
    repeatAdminPassword: 'Password123!',

    // Agree
    agreeTerms: true,
    agreeCookie: true,
    agreePrivacy: true
}

const RegisterComplete = ({ auth, darkMode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, companySignupEndpoints } = useSelector((state) => state.companySignupEndpoints);
    const [hover, setHover] = useState(false);

    console.log('companySignupEndpoints')
    console.log(companySignupEndpoints)
    
    const onHover = () => {
        setHover(!hover)
    };

    const colorOne = darkMode ? '#D3D3D3' : '#fcfcfc';

    return (
        <Box m='20px'>
            
        
        <Grid container display='flex' width='100%' justifyContent={'center'} alignContent='center' alignItems={'center'} justifyItems='center'>
            <Grid item xs={11} md={8} container bgcolor='#f2f0f0' borderRadius={1.5} boxShadow={3} justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                {
                    true &&
                    <>
                        <Box sx={{ textAlign: 'center'}} pb={3} pt={3}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '2em', pb: '3vh' }}>BACKSEAT FLEET</Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.875em', pb: '2vh' }}>Company Registration Completed</Typography>
                            <Typography sx={{ pb: '1vh' }}>You can now login with the credentials you provided. These details, including the password, should also have been emailed to both the company email address and, if provided, notification email address.</Typography>
                            <Typography sx={{ pb: '3vh' }}>If for some reason you are unable to login, please contact Backseat Fleet Support at Support@BackseatFleet.co.uk</Typography>
                            <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                                <ButtonRP to='/login' onMouseEnter={onHover} onMouseLeave={onHover} primary={false} dark={false}>
                                    Navigate to Login Page {hover ? <ArrowForward /> : <ArrowRight />}
                                </ButtonRP>
                            </Box>                            
                        </Box>
                    </>                   
                    
                }

            </Grid>
        </Grid>

        </Box>
  )
}

export default RegisterComplete