import { START_LOADING_PERSONAL_VECHILES_ENDPOINT, END_LOADING_PERSONAL_VECHILES_ENDPOINT, POST_PERSONAL_VECHILES_ENDPOINT_DATA, FETCH_PERSONAL_VECHILES_ENDPOINT_DATA, CLEAR_PERSONAL_VECHILES_ENDPOINT_DATA } from '../constants/actionTypes';

export default (state = { isLoading: true, personalVehiclesEndpoints: [] }, action) => {
    switch (action.type) {
        case START_LOADING_PERSONAL_VECHILES_ENDPOINT:   
            return { ...state, isLoading: true };
        case END_LOADING_PERSONAL_VECHILES_ENDPOINT:
            return { ...state, isLoading: false };;
        case POST_PERSONAL_VECHILES_ENDPOINT_DATA:
            return { ...state, personalVehiclesEndpoints: action.payload };
        case FETCH_PERSONAL_VECHILES_ENDPOINT_DATA:
            return { ...state, personalVehiclesEndpoints: action.payload };
        case CLEAR_PERSONAL_VECHILES_ENDPOINT_DATA:
            return { ...state, personalVehiclesEndpoints: []}
        default:
            return state;
    }
};