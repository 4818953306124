import { AUTH, LOGOUT, NEWUSER, REDIRECT_USER, START_LOGIN_LOADING, END_LOGIN_LOADING, SIGNUP_AUTH, CLEAR_AUTH_DATA } from '../constants/actionTypes';

const authReducer = (state = { isLoading: false, authData: [] }, action) => {
    switch (action.type) {
        case START_LOGIN_LOADING:
            return { ...state, isLoading: true };
        case END_LOGIN_LOADING:
            return { ...state, isLoading: false };
        case AUTH:
            sessionStorage.setItem('profile', JSON.stringify({ ...action?.data }));
            return { ...state, authData: action?.payload };
        case LOGOUT:
            localStorage.clear();
            sessionStorage.clear();
            state = undefined;
            return { undefined, action };
        case SIGNUP_AUTH:
            return {...state, authData: action?.payload};
        case NEWUSER:
            return { ...state, authData: action?.payload };
        default: 
            return state;
    }
};

export default authReducer;