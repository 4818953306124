import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { completeJWTManagerSignup, getJWTData } from '../../../actions/jwtSignup';
import Controls from '../../PageProps/Controls/Controls';
import { useForm, Form } from '../../PageProps/useForm';
import useAuth from '../../../hooks/useAuth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const initialFValues = {
    password: '',
    repeatPassword: '',
    agreeTerms: false,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const JWTExpired = () => {
    // Dispatch to get username, first and last name
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const { isLoading, jwtSignup } = useSelector((state) => state.jwtSignup);
    // console.log(jwtSignup)
    // let jwtSignup
    const handlePopupFailure = () => {
        setOpen(true);
      };
    
      const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };


    useEffect(() => {

    },[])

    const validation = (fieldValues = values) => {
        let temp = {...errors}
        if ('firstName' in fieldValues) {
            temp.firstName = fieldValues.firstName ? "" : "This field is required";
        }
        if ('lastName' in fieldValues) {
            temp.lastName = fieldValues.lastName ? "" : "This field is required";
        }
        if ('emailAddress' in fieldValues) {
            temp.emailAddress = (/^\S+@\S+\.\S+$/).test(fieldValues.emailAddress) ? "" : "Enter a valid email address";
        }
        if ('costCentre' in fieldValues) {
            temp.costCentre = fieldValues.costCentre.length !== 0 ? "" : "You must select a cost centre";
        }
        setErrors({
            ...temp
        })
        if (fieldValues == values) {
            return Object.values(temp).every(x => x === "");
        }
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, false, validation)

    const handleSubmit = (e) => {
        e.preventDefault()
    } 

    return (
    <Box display='flex' width='100%' height='100%' justifyContent={'center'} alignContent='center' alignItems={'center'} justifyItems='center'>
        <Box  width='50%' height='70%' bgcolor='#f2f0f0' boxShadow={3} justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'  p={5}>
            <Form onSubmit={handleSubmit}>
            
            <Box >
                <Typography variant='h3' fontWeight={700} mb={2}>BACKSEAT FLEET</Typography>
            </Box>
            <Box >
                <Typography variant='h6' fontWeight={700} mb={2}></Typography>
            </Box>
            <Box >
                <Typography mb={2}>Welcome</Typography>
            </Box>
            <Box>
                <Typography  mb={2}>Let's finalize your setup, please enter a password to protect your account</Typography>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                <Typography mb={2}>Please confirm that you have read and accept our tems and conditions</Typography>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                <Typography mb={2}>SUBMIT</Typography>
                
            </Box>
        </Form>

        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={jwtSignup?.message === "Validation Failed" ? "Validation Failed" : jwtSignup?.message === "Validation Success" ? "success" : "warning"} sx={{ width: '100%' }}>
        {jwtSignup?.message === "Login failure" ? "Login Failed" : jwtSignup?.message === "Login Success" ? "Login Success" : "Unknown Error"}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default JWTExpired