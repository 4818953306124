import React from 'react';
import { Button } from '@mui/material';

const ActionButton = (props) => {
    const { onClick } = props;
  return (
    <Button variant="contained" color="secondary" size="small" onClick={onClick}>X</Button>
  )
}

export default ActionButton