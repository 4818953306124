import { combineReducers } from "redux";

import authData from './auth';
import companies from './companies';
import users from './users';
import tickets from './tickets';
import orders from './orders';
import cars from './cars';
import costCentres from "./costCentre";
import inviteUser from './inviteUser';
import addCompany from './addCompany';
import messages from './messages';
import dashboardData from './dashboardData';
import carDetails from './carDetails';
import userDetails from './userDetails';
import companyDetails from './companyDetails';
import orderDetails from './orderDetails';
import updateActions from './updateActions';
import ticketDetails from './ticketDetails';
import tokenHistory from './tokenHistory';
import getTokens from './getTokens';
import invoice from './invoice';
import carsCSV from './carsCSV';
import licenceCheckCSV from "./licenceCheckCSV";
import orderHistoryCSV from "./orderHistoryCSV";
import serviceDetailsCSV from "./serviceDetailsCSV";
import tokenUsageHistoryCSV from "./tokenUsageHistoryCSV";
import licenceDVLA from "./licenceDVLA";
import personalVehicles from "./personalVehicles";
import personalVehicle from "./personalVehicle";
import jwtSignup from "./jwtSingnup";
import sendMessages from './sendMessages.js'
import notifications from "./notifications";
import companyPolicies from './companyPolicies'
import employeeSettings from './employeeSettings';


// New Structure Endpoints
import licenceEndpoints from "./licenceEndpoints";

import companyVehiclesEndpoints from "./companyVehiclesEndpoints";

import personalVehicleEndpoints from "./personalVehicleEndpoints";
import personalVehiclesEndpoints from "./personalVehiclesEndpoints";

import employeesDetailsEndpoints from "./employeesDetailsEndpoints";
import employeeDetailsEndpoints from "./employeeDetailsEndpoints";

import companySignupEndpoints from "./companySignupEndpoints";

import managerDetailsEndpoints from "./managerDetailsEndpoints";

import companyManagerEndpoints from "./companyManagerEndpoints";

import companyHouse from "./companyHouse";

export const reducers = combineReducers({ authData, companies, users, tickets, orders, cars, costCentres, inviteUser, 
    addCompany, messages, dashboardData, carDetails, userDetails, companyDetails, orderDetails, updateActions, 
    ticketDetails, tokenHistory, getTokens, invoice, carsCSV, licenceCheckCSV, orderHistoryCSV, serviceDetailsCSV, tokenUsageHistoryCSV,
    licenceDVLA, personalVehicles, personalVehicle, jwtSignup, sendMessages, notifications, companyPolicies, employeeSettings,

    licenceEndpoints, companyVehiclesEndpoints, personalVehiclesEndpoints, personalVehicleEndpoints, employeesDetailsEndpoints, employeeDetailsEndpoints,
    companySignupEndpoints, managerDetailsEndpoints, companyManagerEndpoints, companyHouse
});

