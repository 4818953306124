import * as api from '../api';
import { START_LOADING_COMPANY_SIGNUP_ENDPOINT, END_LOADING_COMPANY_SIGNUP_ENDPOINT, POST_COMPANY_SIGNUP_ENDPOINT_DATA, FETCH_COMPANY_SIGNUP_ENDPOINT_DATA, CLEAR_COMPANY_SIGNUP_ENDPOINT_DATA } from '../constants/actionTypes';

export const addNewCompany = (postData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_COMPANY_SIGNUP_ENDPOINT });
        const { data } = await api.addNewCompany(postData);

        dispatch({ type: POST_COMPANY_SIGNUP_ENDPOINT_DATA , payload: data });
    } catch (error) {
        dispatch({ type: POST_COMPANY_SIGNUP_ENDPOINT_DATA, payload: { message: 'Failure', error: error?.response?.data } });

    }
    dispatch({ type: END_LOADING_COMPANY_SIGNUP_ENDPOINT });
};