
import { START_LOADING_ORDER_HISTORY_CSV, END_LOADING_ORDER_HISTORY_CSV, FETCH_ORDER_HISTORY_CSV, CLEAR_ORDER_HISTORY_CSV } from '../constants/actionTypes';

export default (state = { isLoading: true, orderHistoryCSV: [] }, action) => {
    switch (action.type) {
        case START_LOADING_ORDER_HISTORY_CSV:
            return { ...state, isLoading: true };
        case END_LOADING_ORDER_HISTORY_CSV:
            return { ...state, isLoading: false };;
        case FETCH_ORDER_HISTORY_CSV:
            return { ...state, orderHistoryCSV: action.payload };
        case CLEAR_ORDER_HISTORY_CSV:
            return { ...state, orderHistoryCSV: [] };
        default:
            return state;
    }
};