import { START_ORDER_LOADING, END_ORDER_LOADING, FETCH_ALL_ORDERS, FETCH_ORDER, CREATE_ORDER, UPDATE_ORDER, MANUAL_CREATE_ORDER, COMPANY_ORDER_CREATION } from '../constants/actionTypes';

export default (state = {isLoading: true , orders : []}, action) => {
    switch (action.type) {
        case START_ORDER_LOADING:
            return { ...state, isLoading: true };
        case END_ORDER_LOADING:
            return { ...state, isLoading: false };
        case FETCH_ALL_ORDERS:
            return { ...state, orders: action.payload };
        case FETCH_ORDER:
            return { ...state, orders: action.payload };
        case CREATE_ORDER:
            return { ...state, orders: action.payload };
        case MANUAL_CREATE_ORDER:
            return {...state, orders: action.payload};
        case COMPANY_ORDER_CREATION:
            return {...state, orders: action.payload};
        case UPDATE_ORDER:
            return { ...state, orders: action.payload };
        default:
            return state;
    }
}