import { START_JWT_LOADING, END_JWT_LOADING, FETCH_JWT_DATA, JWT_MANAGE_SIGN_UP, JWT_USER_SIGN_UP, CLEAN_JWT_SIGNUP } from '../constants/actionTypes';

export default (state = { isLoading: true, jwtSignup: [] }, action) => {
    switch (action.type) {
        case START_JWT_LOADING:
            return { ...state, isLoading: true };
        case END_JWT_LOADING:
            return { ...state, isLoading: false };
        case FETCH_JWT_DATA:
            return { ...state, jwtSignup: action.payload };
        case JWT_MANAGE_SIGN_UP:
            return { ...state, jwtSignup: action.payload };
        case JWT_USER_SIGN_UP:
            return { ...state, jwtSignup: action.payload }
        case CLEAN_JWT_SIGNUP:
            return { ...state, jwtSignup: null }
        default:
            return state;
    }
};