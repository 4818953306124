import Input from "./Input.js";
import RadioGroup from "./RadioGroup.js";
import Select from "./Select.js";
import Checkbox from "./Checkbox.js";
import DatePicker from "./DatePicker.js";
import Button from "./Button.js";
import ActionButton from './ActionButton.js';
import AutoComplete from './AutoComplete.js';
import Switch from "./Switch.js";


const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    Switch,
    DatePicker,
    Button,
    ActionButton,
    AutoComplete,
}

export default Controls;
