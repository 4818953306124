import { FETCH_ALL_USERS, FETCH_COMPANY_USERS, FETCH_COMPANY_USERS_ALL, FETCH_USER, CREATE_USER, INVITE_USER, START_USER_LOADING, END_USER_LOADING, DELETE_USER, CLEAR_USERS, COMPLETE_FULL_COMPANY_SIGNUP } from '../constants/actionTypes';

export default (state = { isLoading: true, users: [] }, action) => {
    switch (action.type) {
        case START_USER_LOADING:
            return { ...state, isLoading: true };
        case END_USER_LOADING:
            return { ...state, isLoading: false };
        case CLEAR_USERS:
            return { ...state, users: null}
        case FETCH_ALL_USERS:
            // console.log("Got to users reducers - Fetch Std All Users");
            return { ...state, users: action.payload };
        case FETCH_COMPANY_USERS_ALL:
            // console.log("Got to users reducers - Fetch All Users");
            return { ...state, users: action.payload };
        case FETCH_USER:
            // console.log("Got to users reducers - Fetch User");
            return { ...state, users: action.payload };
        case FETCH_COMPANY_USERS:
            return {...state, users: action.payload};
        case CLEAR_USERS:
            return { ...state, users: null }
        case CREATE_USER:
            // console.log("Got to users reducers- Create");
            return { ...state, users: action.payload };
        case DELETE_USER:
            // console.log("Got to users reducers- Create");
            return { ...state, users: action.payload };
        default:
            return state;
    }
};

