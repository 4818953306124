import { START_ORDER_LOADING, END_ORDER_LOADING, FETCH_ALL_ORDERS, FETCH_ORDER, CREATE_ORDER, UPDATE_ORDER, INVITE_NEW_USER } from '../constants/actionTypes';

export default (state = {isLoading: true, inviteUser: []}, action) => {
    switch (action.type) {
        case INVITE_NEW_USER:
            // console.log("Got to inviteUsers reducers- Invite");
            return { ...state, inviteUser: action.payload };
        default:
            return state;
    }
}