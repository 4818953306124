
import { START_LOADING_TOKEN_HISTORY_CSV, END_LOADING_TOKEN_HISTORY_CSV, FETCH_TOKEN_HISTORY_CSV, CLEAR_TOKEN_CSV } from '../constants/actionTypes';

export default (state = { isLoading: true, tokenUsageHistoryCSV: [] }, action) => {
    switch (action.type) {
        case START_LOADING_TOKEN_HISTORY_CSV:
            return { ...state, isLoading: true };
        case END_LOADING_TOKEN_HISTORY_CSV:
            return { ...state, isLoading: false };;
        case FETCH_TOKEN_HISTORY_CSV:
            return { ...state, tokenUsageHistoryCSV: action.payload };
        case CLEAR_TOKEN_CSV:
            return {...state, tokenUsageHistoryCSV: [] }
        default:
            return state;
    }
};