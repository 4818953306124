import React, { Component } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

import Auth from './components/Auth/Auth';
// import HomePage from './components/Pages/LandingPage/HomePage';
import NotFound from './components/Pages/NotFound/NotFound';
import Unauthorized from './components/Pages/Unauthorized/Unauthorized';

import HttpsRedirect from 'react-https-redirect';

// User Imports
// import StandardUserInterface from './components/Pages/SUI/StandardUserInterface';


import Layout from './components/Pages/Layout/Layout';
import RequireAuth from './components/NewAuth/RequireAuth'

import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';

// User comps
// import ManagerUI from './components/Pages/SMUI/ManagerUI';
// import AdminUI from './components/Pages/SAUI/AdminUI';
import CompleteSignup from './components/Pages/CompleteSignup/CompleteSignup';
import JWTExpired from './components/Pages/CompleteSignup/JWTExpired';
import SUAgreeTerms from './components/Pages/SUI/SUCompleteSignUp/SUAgreeTerms';
import SURedirect from './components/Pages/SUI/SUCompleteSignUp/SURedirect';
import SUFullUser from './components/Pages/SUI/SUCompleteSignUp/SUFullUser';
import SUPartialUser from './components/Pages/SUI/SUCompleteSignUp/SUPartialUser';
// import PermBasedManagerUI from './components/Pages/SMAUI/PermBasedManagerUI';
import Register from './components/Pages/Register/Register';
import RegisterComplete from './components/Pages/Register/RegisterComplete';
import ValidateCert from './components/Pages/Register/ValidateCert';

// Split loading pages
import LoadPermBasedManagerUI from './components/Pages/SMAUI/LoadPermBasedManagerUI';
import LoadHomePage from './components/Pages/LandingPage/LoadHomePage';
import LoadStandardUI from './components/Pages/SUI/LoadStandardUI';
import LoadManagerUI from './components/Pages/SMUI/LoadManagerUI';
import LoadAdminUI from './components/Pages/SAUI/LoadAdminUI';


const App = () => {
    const [theme, colorMode] = useMode();

    return (
        <>       
        <HttpsRedirect>
            <Routes>
                <Route path="/" element={<Layout />}>
                    {/* public routes */}
                    <Route path="/" element={<LoadHomePage />} />
                    <Route path="login" element={<Auth isSignupPage={false} />} />
                    <Route path="register" element={<Register />} />
                    <Route path="RegistrationComplete" element={<RegisterComplete />} />
                    <Route path="Complete/Signup/:id" element={<CompleteSignup />} />
                    <Route path="resendVerification" element={<JWTExpired />} />
                    <Route path="unauthorized" element={<Unauthorized />} />

                    {/* split protected routes user routes */}
                    <Route path='/User' element={<RequireAuth allowedRole={'user'}/>}>
                        <Route path='Dashboard' element={<LoadStandardUI />} />
                        <Route path='MyDetails' element={<LoadStandardUI />} />
                        <Route path='MyLicence' element={<LoadStandardUI />} />
                        <Route path='MyPersonalVehicles' element={<LoadStandardUI />} />
                        <Route path='MyPersonalVehicles/:id' element={<LoadStandardUI />} />
                        <Route path='MyPersonalCompanyVehicles/:id' element={<LoadStandardUI />} />
                        <Route path='MyCompanyVehicles' element={<LoadStandardUI />} />
                        <Route path='Settings' element={<LoadStandardUI />} />
                        <Route path='HelpCentre' element={<LoadStandardUI />} />
                    </Route>
                    
                     {/* protected routes user routes */}
                    <Route path='/GetStarted/:id' element={<SURedirect />} />
                        <Route path='/CompleteSignup' element={<RequireAuth allowedRole={'signup'}/>}>
                        <Route path='FullCompany' element={<SUAgreeTerms />} />
                        <Route path='FullUser' element={<SUFullUser />} />
                        <Route path='PartialUser' element={<SUPartialUser />} />
                    </Route>

                    {/* protected routes company routes */}
                    <Route path='/Company' element={<RequireAuth allowedRole={'company'}/>}>
                        <Route path='Dashboard' element={<LoadManagerUI />} />
                        <Route path='Notifications' element={<LoadManagerUI />} />
                        <Route path='Users/Managers' element={<LoadManagerUI />} />
                        <Route path='Users/Managers/:id' element={<LoadManagerUI />} />
                        <Route path='Users/Employees' element={<LoadManagerUI />} />
                        <Route path='Users/Employees/:id' element={<LoadManagerUI />} />
                        <Route path='Users/Employee/Licence/:id' element={<LoadManagerUI />} />
                        <Route path='Vehicles/PersonalVehicles' element={<LoadManagerUI />} />
                        <Route path='Vehicles/PersonalVehicles/:id' element={<LoadManagerUI />} />
                        <Route path='Vehicles/CompanyVehicles' element={<LoadManagerUI />} />
                        <Route path='Vehicles/CompanyVehicles/:id' element={<LoadManagerUI />} />
                        <Route path='Orders/ActiveOrders' element={<LoadManagerUI />} />
                        <Route path='Orders/CompletedOrders' element={<LoadManagerUI />} />
                        <Route path='Orders/Details/:id' element={<LoadManagerUI />} />
                        <Route path='CostCentres' element={<LoadManagerUI />} />
                        <Route path='CompanyDetails' element={<LoadManagerUI />} />
                        <Route path='Settings' element={<LoadManagerUI />} />
                        <Route path='Support' element={<LoadManagerUI />} />
                        <Route path='Tickets' element={<LoadManagerUI />} />
                        <Route path='Ticket/:id' element={<LoadManagerUI />} />
                        <Route path='Support/Ticket/:id' element={<LoadManagerUI />} />
                    </Route>

                    {/* protected routes company routes */}
                    <Route path='/Manager' element={<RequireAuth allowedRole={'manager'}/>}>
                        <Route path='Dashboard' element={<LoadPermBasedManagerUI />} />
                        <Route path='Notifications' element={<LoadPermBasedManagerUI />} />
                        <Route path='Users/Managers' element={<LoadPermBasedManagerUI />} />
                        <Route path='Users/Managers/:id' element={<LoadPermBasedManagerUI />} />
                        <Route path='Users/Employees' element={<LoadPermBasedManagerUI />} />
                        <Route path='Users/Employees/:id' element={<LoadPermBasedManagerUI />} />
                        <Route path='Users/Employee/Licence/:id' element={<LoadPermBasedManagerUI />} />
                        <Route path='Vehicles/PersonalVehicles' element={<LoadPermBasedManagerUI />} />
                        <Route path='Vehicles/PersonalVehicles/:id' element={<LoadPermBasedManagerUI />} />
                        <Route path='Vehicles/CompanyVehicles' element={<LoadPermBasedManagerUI />} />
                        <Route path='Vehicles/CompanyVehicles/:id' element={<LoadPermBasedManagerUI />} />
                        <Route path='Orders/ActiveOrders' element={<LoadPermBasedManagerUI />} />
                        <Route path='Orders/CompletedOrders' element={<LoadPermBasedManagerUI />} />
                        <Route path='Orders/Details/:id' element={<LoadPermBasedManagerUI />} />
                        <Route path='CostCentres' element={<LoadPermBasedManagerUI />} />
                        <Route path='Permissions' element={<LoadPermBasedManagerUI />} />
                        <Route path='Settings' element={<LoadPermBasedManagerUI />} />
                        <Route path='Support' element={<LoadPermBasedManagerUI />} />
                        <Route path='Support/Ticket/:id' element={<LoadPermBasedManagerUI />} />

                    </Route>

                    {/* protected routes admiin routes */}  
                    <Route sensitive={false} path='/Admin' element={<RequireAuth allowedRole={'site'}/>}>
                        <Route path='Dashboard' element={<LoadAdminUI />} />
                        <Route path='Notifications' element={<LoadAdminUI />} />
                        <Route path='Companies' element={<LoadAdminUI />} />
                        <Route path='Company/:id' element={<LoadAdminUI />} />
                        <Route path='CarUpdates' element={<LoadAdminUI />} />
                        <Route path='LicenceUpdates' element={<LoadAdminUI />} />
                        <Route path='InsuranceChecks' element={<LoadAdminUI />} />
                        <Route path='Managers' element={<LoadAdminUI />} />
                        <Route path='Managers/:id' element={<LoadAdminUI />} />
                        <Route path='Employees' element={<LoadAdminUI />} />
                        <Route path='Employees/:id' element={<LoadAdminUI />} />
                        <Route path='Employee/Licence/:id' element={<LoadAdminUI />} />
                        <Route path='Vehicles/PersonalVehicles' element={<LoadAdminUI />} />
                        <Route path='Vehicles/PersonalVehicles/:id' element={<LoadAdminUI />} />
                        <Route path='Vehicles/CompanyVehicles' element={<LoadAdminUI />} />
                        <Route path='Vehicles/CompanyVehicles/:id' element={<LoadAdminUI />} />
                        <Route path='Orders/ActiveOrders' element={<LoadAdminUI />} />
                        <Route path='Orders/CompletedOrders' element={<LoadAdminUI />} />
                        <Route path='Orders/Details/:id' element={<LoadAdminUI />} />
                        <Route path='CostCentres' element={<LoadAdminUI />} />
                        <Route path='ActiveTickets' element={<LoadAdminUI />} />
                        <Route path='ClosedTickets' element={<LoadAdminUI />} />
                        <Route path='CompanyDetails' element={<LoadAdminUI />} />
                        <Route path='Settings' element={<LoadAdminUI />} />
                        <Route path='Support' element={<LoadAdminUI />} />
                        <Route path='Support/Ticket/:id' element={<LoadAdminUI />} />
                    </Route>


                    {/* catchall routes */}
                    <Route path='*' exact={true} element={<NotFound />} /> 
                </Route>
                
            </Routes>
            </HttpsRedirect>    
               </>
    ) 
};

export default App;