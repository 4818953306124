import { CircularProgress } from '@mui/material'
import React, { Suspense } from 'react'

const LoadStandardUI = () => {
    const OtherComponent = React.lazy(() => import('./StandardUserInterface'));

  return (
    <Suspense fallback={<CircularProgress />}>
        <OtherComponent />
    </Suspense>
  )
}

export default LoadStandardUI