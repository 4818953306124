import {START_EMPLOYEE_SETTINGS_LOADING, END_EMPLOYEE_SETTINGS_LOADING, FETCH_EMPLOYEE_SETTINGS } from '../constants/actionTypes';


export default (state = { isLoading: true, employeeSettings: [] }, action) => {
    switch (action.type) {
        case START_EMPLOYEE_SETTINGS_LOADING:
            return { ...state, isLoading: true };
        case END_EMPLOYEE_SETTINGS_LOADING:
            return { ...state, isLoading: false };
        case FETCH_EMPLOYEE_SETTINGS:
            return { ...state, employeeSettings: action.payload}
        default:
            return state;
    }
};

