import { START_HISTORY_LOADING, END_HISTORY_LOADING, FETCH_ALL_HISTORY, UPDATE_HISTORY, FETCH_HISTORY_DETAILS } from '../constants/actionTypes';

export default (state = { isLoading: true, tokenHistory: [] }, action) => {
    switch (action.type) {
        case START_HISTORY_LOADING:
            return {...state, isLoading: true};
        case END_HISTORY_LOADING:
            return {...state, isLoading: false};
        case FETCH_ALL_HISTORY:
            return { ...state, tokenHistory: action.payload };
        case FETCH_HISTORY_DETAILS:
            return { ...state, tokenHistory: action.payload };
        case UPDATE_HISTORY:
            return { ...state, tokenHistory: action.payload };
        default:
            return state;
    }
};