import { Button as MuiButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'



const Button = (props) => {
    const { text, size, color, variant, onClick, disabled=false, ...other } = props;

    return (
        <MuiButton 
        variant={variant}
        size={size}
        color={color}
        onClick={onClick}
        disabled={disabled}
        {...other}
        >{text}</MuiButton>
    )
}

export default Button