import { START_CARS_LOADING, END_CARS_LOADING, FETCH_USERS_CARS, FETCH_ALL_GREY, FETCH_ALL_CORP, FETCH_GREY, FETCH_CORP, CREATE_GREY, CREATE_CORP, UPDATE_GREY, UPDATE_CORP, DELETE_GREY, DELETE_CORP } from '../constants/actionTypes';

export default (state = { isLoading: true, cars: [] }, action) => {
    switch (action.type) {
        case START_CARS_LOADING:
            return { ...state, isLoading: true };
        case END_CARS_LOADING:
            return { ...state, isLoading: false };
        case FETCH_USERS_CARS:
            return { ...state, cars: action.payload };
        case FETCH_ALL_GREY:
            // console.log("Got to users reducers - Fetch All Grey");
            return { ...state, cars: action.payload };
        case FETCH_ALL_CORP:
            // console.log("Got to users reducers - Fetch All Corp");
            return { ...state, cars: action.payload };
        case FETCH_GREY:
            // console.log("Got to users reducers - Fetch Grey");
            return { ...state, cars: action.payload };
        case FETCH_CORP:
            // console.log("Got to users reducers - Fetch Corp");
            return { ...state, cars: action.payload };
        case CREATE_GREY:
            // console.log("Got to users reducers- Create Grey");
            return { ...state, cars: action.payload };
        case CREATE_CORP:
            // console.log("Got to users reducers- Create Corp");
            return { ...state, cars: action.payload };
        case UPDATE_GREY:
            // console.log("Got to users reducers- Update Grey");
            return { ...state, cars: action.payload };
        case UPDATE_CORP:
            // console.log("Got to users reducers- Update Corp");
            return { ...state, cars: action.payload };
        case DELETE_GREY:
            // console.log("Got to users reducers- Remove Grey");
            return { ...state, cars: action.payload };
        case DELETE_CORP:
            // console.log("Got to users reducers- Remove Corp");
            return { ...state, cars: action.payload };
        default:
            return state;
    }
};