import { START_CARS_DETAILS_LOADING, END_CARS_DETAILS_LOADING, FETCH_CAR_DETAILS, FETCH_GREY_CAR_DETAILS, FETCH_CORP_CAR_DETAILS, UPDATE_CORP_MILAGE, UPDATE_CORP_INSURANCE, UPDATE_CORP_ACCIDENT, UPDATE_GREY_MILAGE, UPDATE_CORP_SERVICE, UPDATE_CAR_DETAILS } from '../constants/actionTypes';

export default (state = { isLoading: true, carDetails: [] }, action) => {
    switch (action.type) {
        case START_CARS_DETAILS_LOADING:
            return { ...state, isLoading: true };
        case END_CARS_DETAILS_LOADING:
            return { ...state, isLoading: false };
        case FETCH_CAR_DETAILS:
            return { ...state, carDetails: action.payload };
        case FETCH_GREY_CAR_DETAILS:
            return { ...state, carDetails: action.payload };
        case FETCH_CORP_CAR_DETAILS:
            // console.log("Got to get corp details reducer")
            return { ...state, carDetails: action.payload };
        case UPDATE_CORP_MILAGE:
            // console.log("Got to update corp milage reducer")
            return { ...state, carDetails: action.payload };
        case UPDATE_GREY_MILAGE:
            // console.log("Got to update corp milage reducer")
            return { ...state, carDetails: action.payload };
        case UPDATE_CAR_DETAILS:
            // console.log("Got to update car details reducer")
            return { ...state, carDetails: action.payload };
        case UPDATE_CORP_SERVICE:
            return {...state, carDetails: action.payload };
        case UPDATE_CORP_ACCIDENT:
            return {...state, carDetails: action.payload };
        case UPDATE_CORP_INSURANCE:
            return {...state, carDetails: action.payload };
        default:
            return state;
    }
};