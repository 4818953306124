import { START_LOADING_PERSONAL_VECHILE_ENDPOINT, END_LOADING_PERSONAL_VECHILE_ENDPOINT, POST_PERSONAL_VECHILE_ENDPOINT_DATA, FETCH_PERSONAL_VECHILE_ENDPOINT_DATA, CLEAR_PERSONAL_VECHILE_ENDPOINT_DATA } from '../constants/actionTypes';

export default (state = { isLoading: true, personalVehicleEndpoints: [] }, action) => {
    switch (action.type) {
        case START_LOADING_PERSONAL_VECHILE_ENDPOINT:   
            return { ...state, isLoading: true };
        case END_LOADING_PERSONAL_VECHILE_ENDPOINT:
            return { ...state, isLoading: false };;
        case POST_PERSONAL_VECHILE_ENDPOINT_DATA:
            return { ...state, personalVehicleEndpoints: action.payload };
        case FETCH_PERSONAL_VECHILE_ENDPOINT_DATA:
            return { ...state, personalVehicleEndpoints: action.payload };
        case CLEAR_PERSONAL_VECHILE_ENDPOINT_DATA:
            return { ...state, personalVehicleEndpoints: []}
        default:
            return state;
    }
};