import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import { Chip } from '@mui/material';
import React from 'react'
import { Autocomplete as AutoCompleteOne, TextField } from '@mui/material';

const AutoComplete = (props) => {
    const { name, label, value, onChange, error=null, options, disabled=false, multiSelect=false, native=false } = props;

    // Took out defaultValue={[top100Films[13]]}
    return (
        
        <FormControl
        variant='outlined' {...(error && {error:true})} disabled={disabled}>
            <InputLabel>{label}</InputLabel>
            <AutoCompleteOne
                name={name}
                multiple
                id="size-small-filled-multi"
                size="small"
                options={options}
                values={value}
                onChange={onChange}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}

                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                    variant="outlined"
                    label={option.title}
                    size="small"
                    {...getTagProps({ index })}
                    />
                ))
                }
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="filled"
                    
                    
                />
                )}
                
            >
            {
                    
                }
            </AutoCompleteOne>
            {error && (<FormHelperText>{error}</FormHelperText>)}
        </FormControl>
    )
}

export default AutoComplete