
import { START_LOADING_SERVICE_CSV, END_LOADING_SERVICE_CSV, FETCH_LOADING_SERVICE_CSV, CLEAR_SERVICE_CSV } from '../constants/actionTypes';

export default (state = { isLoading: true, serviceDetailsCSV: [] }, action) => {
    switch (action.type) {
        case START_LOADING_SERVICE_CSV:
            return { ...state, isLoading: true };
        case END_LOADING_SERVICE_CSV:
            return { ...state, isLoading: false };;
        case FETCH_LOADING_SERVICE_CSV:
            return { ...state, serviceDetailsCSV: action.payload };
        case CLEAR_SERVICE_CSV:
            return {...state, serviceDetailsCSV: []}
        default:
            return state;
    }
};