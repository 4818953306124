import { CircularProgress } from '@mui/material'
import React, { Suspense } from 'react'

const LoadAdminUI = () => {
    const OtherComponent = React.lazy(() => import('./AdminUI'));
  return (
    <Suspense fallback={<CircularProgress />}>
        <OtherComponent />
    </Suspense>
  )
}

export default LoadAdminUI