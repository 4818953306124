import { Box, Typography, IconButton, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import Controls from '../../PageProps/Controls/Controls';
// import { useForm, Form } from '../../PageProps/useForm';
// import useAuth from '../../../hooks/useAuth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { LOGOUT } from '../../../../constants/actionTypes'; 
import Controls from '../../../PageProps/Controls/Controls';
import { useForm, Form } from '../../../PageProps/useForm';
import useAuth from '../../../../hooks/useAuth';

import LogoutIcon from '@mui/icons-material/Logout';
import { getUserDetails, completeSignupFullCompany } from '../../../../actions/userDetails';

const initialFValues = {


    agreeTerms: false,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  


const SUAgreeTerms = () => {
    // Dispatch to get username, first and last name
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { setAuth } = useAuth({});
    const [open, setOpen] = useState(false);
    const { isLoading: isUDLoading, userDetails } = useSelector((state) => state.userDetails);
    const { isLoading, authData } = useSelector((state) => state.authData);
    // console.log(userDetails)
    // console.log("Auth Data")
    // console.log(authData)
    useEffect(() => {
      if(authData?.message === "Login failure") {
        // console.log("Triggered Cond")
        handlePopupFailure();
      } else if  (authData?.message === "Login Success") {
        setAuth(authData?.authDetails)
        // console.log(authData?.authDetails)
        navigate(authData?.authDetails?.targetLocation)
      }
    }, [authData])



    // let jwtSignup
    const handlePopupFailure = () => {
        setOpen(true);
      };
    
      const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const logout = () => {
        dispatch({ type: LOGOUT });
        navigate('/login');
      }

    useEffect(() => {
      dispatch(getUserDetails({ auth: auth?.token }))
    },[])

    const validation = (fieldValues = values) => {
        let temp = {...errors}
        if ('agreeTerms' in fieldValues) {
            temp.agreeTerms = fieldValues.agreeTerms ? "" : "Must agree to procceed";
        }

        setErrors({
            ...temp
        })
        if (fieldValues == values) {
            return Object.values(temp).every(x => x === "");
        }
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validation)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validation()) {
          dispatch(completeSignupFullCompany({ auth: auth?.token, values }))
        }
    } 

    return (
    <Box display='flex' width='100%' height='100%' justifyContent={'center'} alignContent='center' alignItems={'center'} justifyItems='center'>
        
        <Box  width='50%' height='70%' bgcolor='#f2f0f0' boxShadow={3} justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'  p={5}>
            <Box display='flex' justifyContent='right'>
            <IconButton onClick={logout}>
              <LogoutIcon />
            </IconButton>
            </Box>
            <Form onSubmit={handleSubmit}>
            
            <Box >
                <Typography variant='h3' fontWeight={700} mb={2}>BACKSEAT FLEET</Typography>
            </Box>
            <Box >
                <Typography variant='h6' fontWeight={700} mb={2}>{}</Typography>
            </Box>
            <Box >
                <Typography mb={2}>Welcome {userDetails?.userToReturn ? userDetails?.userToReturn?.firstName + " " + userDetails?.userToReturn?.lastName : null} {userDetails?.userToReturn ? ' of ' + userDetails?.userToReturn?.userComapny : null}</Typography>
            </Box>
            <Box>
                <Typography  mb={2}>Let's finalize your setup!</Typography>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                <Typography  mb={2} flexBasis='100%'>Before we activate your account you need to read and agree to our terms and conditions.</Typography>
              
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                <Typography  mb={2} flexBasis='30%'>LINK</Typography>

            </Box>
            <Box display='flex' justifyContent='center'>
                        <Controls.Checkbox 
                            name="agreeTerms"
                            label="I Agree to Terms and Conditions"
                            value={values.agreeTerms}
                            onChange={handleInputChange}
                            error={errors.agreeTerms}
                        />
                        </Box>
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
              <Button variant='outlined' type='submit'>Submit</Button>
            </Box>

        </Form>

        </Box>
        
    </Box>
  )
}


export default SUAgreeTerms