import { Box, Typography, IconButton, Button, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import Controls from '../../PageProps/Controls/Controls';
// import { useForm, Form } from '../../PageProps/useForm';
// import useAuth from '../../../hooks/useAuth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { LOGOUT } from '../../../../constants/actionTypes'; 
import Controls from '../../../PageProps/Controls/Controls';
import { useForm, Form } from '../../../PageProps/useForm';
import useAuth from '../../../../hooks/useAuth';
import { CheckBox, Visibility, VisibilityOff } from '@mui/icons-material';



import LogoutIcon from '@mui/icons-material/Logout';
import { getUserDetails } from '../../../../actions/userDetails';
import { completeSignupFullCompany } from '../../../../actions/users';

const initialFValues = {

    firstName: '',
    lastName: '',
    houseNumber: '',
    streetName: '',
    location: '',
    postcode: '',
    telNumber: '',
    userName: '',
    dateOfBirth: '',
    emailAddress: '',
    password: '',
    repeatPassword: '',
    agreeTerms: false,
    signupType: 'partialUser'
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  


const SUPartialUser = () => {
    // Dispatch to get username, first and last name
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { setAuth } = useAuth({});
    const [open, setOpen] = useState(false);
    const { isLoading: isUDLoading, userDetails } = useSelector((state) => state.userDetails);
    const { isLoading: isAuthLoading, authData } = useSelector((state) => state.authData);
    const { isLoading: isUsersLoading, users } = useSelector((state) => state.users);
    // console.log("User details")
    // console.log(userDetails)
    // console.log("Auth Data")
    // console.log(authData)
    // console.log("user data")
    // console.log(users)
    // useEffect(() => {
    //   if(authData?.message === "Login failure") {
    //     // console.log("Triggered Cond")
    //     handlePopupFailure();
    //   } else if  (authData?.message === "Login Success") {
    //     setAuth(authData?.authDetails)
    //     // console.log(authData?.authDetails)
    //     navigate(authData?.authDetails?.targetLocation)
    //   }
    // }, [authData])


    useEffect(() => {
        if (users?.authDetails && users?.message === 'Successfully completed sign up') {
            setAuth(users?.authDetails)       
            navigate(users?.authDetails?.targetLocation)
        }
    },[users])

    if (userDetails?.message === 'Successfully got user details' && userDetails?.userToReturn) {
        initialFValues.firstName = userDetails?.userToReturn?.firstName
        initialFValues.lastName = userDetails?.userToReturn?.lastName
        initialFValues.userName = userDetails?.userToReturn?.userName
        initialFValues.emailAddress = userDetails?.userToReturn?.emailAddress

    }

    // let jwtSignup
    const handlePopupFailure = () => {
        setOpen(true);
      };
    
      const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const logout = () => {
        dispatch({ type: LOGOUT });
        navigate('/login');
      }

    useEffect(() => {
      dispatch(getUserDetails({ auth: auth?.token }))
    },[])

    const validation = (fieldValues = values) => {
        let temp = {...errors}
        if ('agreeTerms' in fieldValues) {
            temp.agreeTerms = fieldValues.agreeTerms === true ? "" : "Must agree to procceed";
        }
        if ('firstName' in fieldValues) {
            temp.firstName = fieldValues.firstName ? "" : "Must agree to procceed";
        }
        if ('lastName' in fieldValues) {
            temp.lastName = fieldValues.lastName ? "" : "Must agree to procceed";
        }
        if ('userName' in fieldValues) {
            temp.userName = fieldValues.userName ? "" : "Must agree to procceed";
        }
        if ('houseNumber' in fieldValues) {
            temp.houseNumber = fieldValues.houseNumber ? "" : "Must agree to procceed";
        }
        if ('streetName' in fieldValues) {
            temp.streetName = fieldValues.streetName ? "" : "Must agree to procceed";
        }
        if ('location' in fieldValues) {
            temp.location = fieldValues.location ? "" : "Must agree to procceed";
        }
        if ('postcode' in fieldValues) {
            temp.postcode = (/^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i).test(fieldValues.postcode) ? "" : "Enter valid postcode, contactact support if correct is blocked";
        }
        if ('emailAddress' in fieldValues) {
            temp.emailAddress = (/^\S+@\S+\.\S+$/).test(fieldValues.emailAddress) ? "" : "Enter a valid email address";
        }
        
        setErrors({
            ...temp
        })
        if (fieldValues == values) {
            return Object.values(temp).every(x => x === "");
        }
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validation)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validation()) {
            // console.log(auth)
            dispatch(completeSignupFullCompany({ auth: auth?.token, values, inviteJWT: auth?.inviteJWT }))
        }
    } 

    return (
    <Box display='flex' width='100%' height='100%' justifyContent={'center'} alignContent='center' alignItems={'center'} justifyItems='center'>
        
        <Box  width='50%' minHeight='70%' bgcolor='#f2f0f0' boxShadow={3}  p={5}>
            <Box display='flex' justifyContent='right'>
            <IconButton onClick={logout}>
              <LogoutIcon />
            </IconButton>
            </Box>
            <Form onSubmit={handleSubmit}>
            
            <Box >
                <Typography variant='h3' fontWeight={700} mb={2}>BACKSEAT FLEET</Typography>
            </Box>
            <Box >
                <Typography variant='h6' fontWeight={700} mb={2}>{}</Typography>
            </Box>
            <Box >
                <Typography mb={2}>Welcome {userDetails?.userToReturn ? userDetails?.userToReturn?.firstName + " " + userDetails?.userToReturn?.lastName : null} {userDetails?.userToReturn ? ' of ' + userDetails?.userToReturn?.userComapny : null}</Typography>
            </Box>
            <Box>
                <Typography  mb={2}>Let's finalize your setup!</Typography>
            </Box>
            <Box display='flex'>
                <Typography  mb={2} flexBasis='100%'>Before we activate your account you need fil in a few deatils and read and agree to our terms and conditions.</Typography>
              
            </Box>
            <Box display='flex'>
                <Typography  mb={2} flexBasis='30%'>Username</Typography>
                <Controls.Input 
                        name="userName"
                        label="Username"
                        disabled={true}
                        value={values.userName}
                        onChange={handleInputChange}
                        error={errors.userName}
                    />
            </Box>
            <Box display='flex'>
                <Typography  mb={2} flexBasis='30%'>Full Name</Typography>
                <Controls.Input 
                        name="firstName"
                        label="First Name"
                        value={values.firstName}
                        onChange={handleInputChange}
                        error={errors.firstName}
                    />
                <Controls.Input 
                        name="lastName"
                        label="Last Name"
                        value={values.lastName}
                        onChange={handleInputChange}
                        error={errors.lastName}
                    />
            </Box>
            <Box display='flex'>
                <Typography  mb={2} flexBasis='30%'>D.O.B</Typography>
                <Controls.Input 
                        name="dateOfBirth"
                        type='date'
                        value={values.dateOfBirth}
                        onChange={handleInputChange}
                        error={errors.dateOfBirth}
                    />
            </Box>
            <Box display='flex'>
                <Typography  mb={2} flexBasis='30%'>Email Address</Typography>
                <Controls.Input 
                        name="emailAddress"
                        label="Email Address"
                        disabled={true}
                        width='350px'
                        value={values.emailAddress}
                        onChange={handleInputChange}
                        error={errors.emailAddress}
                    />
            </Box>
            <Box display='flex'>
                <Typography  mb={2} flexBasis='30%'>Address</Typography>
                <Controls.Input 
                        name="houseNumber"
                        label="House Number/Name"
                        value={values.houseNumber}
                        onChange={handleInputChange}
                        error={errors.houseNumber}
                    />
                <Controls.Input 
                        name="streetName"
                        label="Street Name"
                        value={values.streetName}
                        onChange={handleInputChange}
                        error={errors.streetName}
                    />
            </Box>
            <Box display='flex'>
            <Typography  mb={2} flexBasis='30%'></Typography>
                
                <Controls.Input 
                        name="location"
                        label="City/Town/Village"
                        value={values.location}
                        onChange={handleInputChange}
                        error={errors.location}
                    />
                <Controls.Input 
                        name="postcode"
                        label="Postcode"
                        value={values.postcode}
                        onChange={handleInputChange}
                        error={errors.postcode}
                    />
            </Box>
            <Box display='flex'>
                <Typography  mb={2} flexBasis='30%'>Phone</Typography>
                <Controls.Input 
                        name="telNumber"
                        label="Phone Number (Optional)"
                        value={values.telNumber}
                        onChange={handleInputChange}
                        error={errors.telNumber}
                    />
            </Box>
            
            <Box display='flex'>
                <Typography  mb={2} flexBasis='30%'>Password</Typography>
            </Box>

                        <Box display='flex'>
                            <Box flexBasis='50%'>
                            <Controls.Input
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleInputChange}
                                autoComplete='new-password'
                                error={errors.password}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword === false ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                                
                            />
                            </Box>
                            <Box flexBasis='50%'>
                            <Controls.Input
                                name="repeatPassword"
                                label="Repeat Password"
                                autoComplete='new-password'
                                type={showPassword ? 'text' : 'password'}
                                value={values.repeatPassword}
                                onChange={handleInputChange}
                                error={errors.repeatPassword}
                                
                            />
                            </Box>
                        </Box>
            <Box display='flex' justifyContent='center'>
                        <Controls.Checkbox 
                            name="agreeTerms"
                            label="I Agree to Terms and Conditions"
                            value={values.agreeTerms}
                            onChange={handleInputChange}
                            error={errors.agreeTerms}
                        />
                        </Box>
            <Box display='flex'>
              <Button variant='outlined' type='submit'>Submit</Button>
            </Box>

        </Form>

        </Box>
        
    </Box>
  )
}


export default SUPartialUser