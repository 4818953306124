import { LOGOUT } from '../../../constants/actionTypes';

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';


const Unauthorized = () => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(function() {
      dispatch({ type: LOGOUT });

      navigate('/login')
      
    }, 2200);
  },[])

  return (
    <div>Unauthorized</div>
  )
}

export default Unauthorized