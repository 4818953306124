import { START_UPDATE_ACTIONS_LOADING, END_UPDATE_ACTIONS_LOADING, UPDATE_ALL_CARS, UPDATE_CARS, UPDATE_ALL_LICENCES, UPDATE_LICENCE, SEND_EMAIL } from '../constants/actionTypes';

export default (state = { isLoading: true, updateActions: [] }, action) => {
    switch (action.type) {
        case START_UPDATE_ACTIONS_LOADING:
            return {...state, isLoading: true};
        case END_UPDATE_ACTIONS_LOADING:
            return {...state, isLoading: false};
        case UPDATE_ALL_CARS:
            return {...state, updateActions: action.payload}
        case UPDATE_CARS:
            return {...state, updateActions: action.payload}
        case UPDATE_ALL_LICENCES:
            return {...state, updateActions: action.payload}
        case UPDATE_LICENCE:
            return {...state, updateActions: action.payload}
        case SEND_EMAIL:
            return {...state, updateActions: action.payload}
        default:
            return state;
    }
};