import { FormControl, FormControlLabel, Checkbox as MuiCheckbox, FormHelperText } from '@mui/material';
import React from 'react'

const Checkbox = (props) => {
    const {name, label, value, onChange, disabled, error=null, showFullColor= false, ...other } = props;

    const converToDefEventparam = (name,value) => ({
        target:{
            name:name,
            value:value
        }
     });

    return (
        <FormControl >
            <FormControlLabel
                control={<MuiCheckbox 
                            name={name}
                            color="primary"
                            checked={value}
                            disabled={disabled}
                            onChange={e => onChange(converToDefEventparam(name, e.target.checked))}
                            {...other}
                        />
                    } 
                label={label}  
            />
        {error ? 
        <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>
        :
        null
        }
            
        </FormControl>
    )
}

export default Checkbox