import * as api from '../api';
import { FETCH_ALL_USERS, FETCH_COMPANY_USERS, FETCH_COMPANY_USERS_ALL, FETCH_USER, CREATE_USER, START_USER_LOADING, END_USER_LOADING, DELETE_USER, COMPLETE_FULL_COMPANY_SIGNUP, CLEAR_USERS } from '../constants/actionTypes';

export const getUsers = (companyID) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING });
        // console.log("GOT TO ACTIONS - getUsers");
        const { data } = await api.getUsers(companyID);
        
        dispatch({type: FETCH_ALL_USERS, payload: data});
    } catch (error) {
        dispatch({ type: FETCH_ALL_USERS, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_LOADING });
};

export const getManagementUsers = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING });
        const { data } = await api.getManagementUsers(details);

        dispatch({type: FETCH_COMPANY_USERS, payload: data});

    } catch (error) {
        // console.log(error);
        dispatch({ type: FETCH_COMPANY_USERS, payload: { message: 'Failure', error: error?.response?.data } });        
    }
    dispatch({ type: END_USER_LOADING });

}

export const getUser = (id) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING });
        const { data } = await api.getUser(id);

        dispatch({type: FETCH_USER, payload: data});
    } catch (error) {
        // console.log(error);
        dispatch({ type: FETCH_USER, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_LOADING });
};

export const getCompanyUsers = (requestDetails) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING });
        const { data } = await api.getCompanyUsers(requestDetails);

        dispatch({type: FETCH_COMPANY_USERS, payload: data});

    } catch (error) {
        // console.log(error);
        dispatch({ type: FETCH_COMPANY_USERS, payload: { message: 'Failure', error: error?.response?.data } });        
    }
    dispatch({ type: END_USER_LOADING });

}

export const getCompanyUsersAll = (requestDetails) => async (dispatch) => {
    try {
        // console.log("Get all users")
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING });
        const { data } = await api.getCompanyUsersAll(requestDetails);

        dispatch({type: FETCH_COMPANY_USERS_ALL, payload: data});

    } catch (error) {
        // console.log(error);
        dispatch({ type: FETCH_COMPANY_USERS_ALL, payload: { message: 'Failure', error: error?.response?.data } });

        
    }
    dispatch({ type: END_USER_LOADING });

}

export const createUser = (newUserData) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING });
        const { data } = await api.createUser(newUserData);

        dispatch({type: CREATE_USER, payload: data});
    } catch (error) {
        // console.log(error);
        dispatch({ type: CREATE_USER, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_LOADING });
};

export const deleteUser = (userId) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING });
        
        const { data } = await api.deleteUser(userId);

        dispatch({type: DELETE_USER, payload: data});
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: DELETE_USER, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_LOADING });

}

export const completeSignupFullCompany = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USERS });
        dispatch({ type: START_USER_LOADING })

        dispatch({ type: CLEAR_USERS })
        const { data } = await api.completeSignupFullCompany(details);

        dispatch({ type: COMPLETE_FULL_COMPANY_SIGNUP, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: COMPLETE_FULL_COMPANY_SIGNUP, payload: { message: 'Failure', error: error?.response?.data } });        
    }

    dispatch({ type: END_USER_LOADING  })
}