import { START_LOADING_DVLA_LICENCE_CHECK, END_LOADING_DVLA_LICENCE_CHECK, FETCH_TEST_DATA, CLEAR_LICENCE_DVLA_DATA } from '../constants/actionTypes';

export default (state = { isLoading: true, licenceDVLA: [] }, action) => {
    switch (action.type) {
        case START_LOADING_DVLA_LICENCE_CHECK:
            return { ...state, isLoading: true };
        case END_LOADING_DVLA_LICENCE_CHECK:
            return { ...state, isLoading: false };;
        case FETCH_TEST_DATA:
            return { ...state, licenceDVLA: action.payload };
        case CLEAR_LICENCE_DVLA_DATA:
            return { ...state, licenceDVLA: []}
        default:
            return state;
    }
};