import { START_INVOICE_LOADING, END_INVOICE_LOADING, FETCH_INVOICE_AMOUNTS } from '../constants/actionTypes';


export default (state = { isLoading: true, invoice: [] }, action) => {
    switch (action.type) {
        case START_INVOICE_LOADING:
            return { ...state, isLoading: true };
        case END_INVOICE_LOADING:
            return { ...state, isLoading: false };
        case FETCH_INVOICE_AMOUNTS:
            return { ...state, invoice: action.payload}
        default:
            return state;
    }
};
