import { START_DASHBOARD_LOADING, END_DASHBOARD_LOADING, FETCH_DASHBOARD_DATA, CREATE_NEW_DASHBOARD, CLEAR_AUTH_DATA } from '../constants/actionTypes';

export default (state = { isLoading: true, dashboardData: [] }, action) => {
    switch (action.type) {
        case START_DASHBOARD_LOADING:
            return { ...state, isLoading: true };
        case END_DASHBOARD_LOADING:
            return { ...state, isLoading: false };
        case FETCH_DASHBOARD_DATA:
            // console.log("Got to fetch dashboarddata reducer");
            return { ...state, dashboardData: action.payload };
        case CREATE_NEW_DASHBOARD:
            // console.log("Got to create dashboard reducer");
            return { ...state, dashboardData: action.payload };
        case CLEAR_AUTH_DATA:
            return { ...state, dashboardData: null }
        default:
            return state;
    }
};

