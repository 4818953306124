import { Box, Typography, IconButton, Button, InputAdornment, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import Controls from '../../PageProps/Controls/Controls';
// import { useForm, Form } from '../../PageProps/useForm';
// import useAuth from '../../../hooks/useAuth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { LOGOUT } from '../../../../constants/actionTypes'; 
import Controls from '../../../PageProps/Controls/Controls';
import { useForm, Form } from '../../../PageProps/useForm';
import useAuth from '../../../../hooks/useAuth';
import { CheckBox, Visibility, VisibilityOff } from '@mui/icons-material';



import LogoutIcon from '@mui/icons-material/Logout';
import { getUserDetails, completeSignupFullCompany } from '../../../../actions/userDetails';
import TermsAndCondModal from './TermsAndCondModal/TermsAndCondModal';

const initialFValues = {

    firstName: '',
    lastName: '',

    houseNumber: '',
    streetName: '',
    location: '',
    postcode: '',

    telNumber: '',

    userName: '',

    dateOfBirth: '',

    password: '',
    repeatPassword: '',

    agreeTerms: false,

    emailAddress: '',

    signupType: 'fullUser'
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  


const SUFullUser = () => {
    // Dispatch to get username, first and last name
    const [showPassword, setShowPassword] = useState(false);
    const [ openTerms, setOpenTerms ] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { setAuth } = useAuth({});
    const [open, setOpen] = useState(false);
    const [pressed, setPressed] = useState(false);
    const { isLoading: isUDLoading, userDetails } = useSelector((state) => state.userDetails);
    const { isLoading, authData } = useSelector((state) => state.authData);
    useEffect(() => {
      if(authData?.message === "Not valid or expired token") {
        // console.log("Triggered Cond")
        navigate(userDetails?.authDetails?.targetLocation)
        handlePopupFailure();
      } else if (userDetails?.message === "Successfully completed sign up") {
        setAuth(userDetails?.authDetails)
        navigate(userDetails?.authDetails?.targetLocation)
      }
    }, [userDetails])



    // let jwtSignup
    const handlePopupFailure = () => {
        setOpen(true);
      };
    
      const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const logout = () => {
        dispatch({ type: LOGOUT });
        navigate('/login');
      }

    useEffect(() => {
      dispatch(getUserDetails({ auth: auth?.token, inviteJWT: authData?.authDetails?.inviteJWT }))
    },[])

    useEffect(() => {
        if (userDetails?.userToReturn?.emailAddress) {
            initialFValues.emailAddress = userDetails?.userToReturn?.emailAddress
            initialFValues.firstName = userDetails?.userToReturn?.firstName
            initialFValues.lastName = userDetails?.userToReturn?.lastName
            initialFValues.userName = userDetails?.userToReturn?.userName
        }
        if (pressed) {
            setPressed(false)
        }
    },[userDetails])

    const checkPasswordsMatch = (newValues) => {
        let returnedString;
        if (newValues.password === '') {
            returnedString = 'Please Enter a Password'
        } else if (!(newValues.password === newValues.repeatPassword) ) {
            returnedString = 'Passwords must match'
        } else if (!((/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/).test(newValues?.password))) {
            returnedString = 'Must be eight characters, at least one letter, one number and one special character.'
        }

        return returnedString
    }

    const validation = (fieldValues = values) => {
        let temp = {...errors}
        if ('agreeTerms' in fieldValues) {
            temp.agreeTerms = fieldValues.agreeTerms ? "" : "Must agree to procceed";
        }

        if ('firstName' in fieldValues) {
            temp.firstName = fieldValues.firstName ? "" : "This field is required";
            if (temp.firstName === '') {
                temp.firstName = fieldValues.firstName.length > 2 && fieldValues.firstName.length < 25? "" : "Must be between 3-24 characters";
            }
        }

        if ('lastName' in fieldValues) {
            temp.lastName = fieldValues.lastName ? "" : "This field is required";
            if (temp.lastName === '') {
                temp.lastName = fieldValues.lastName.length > 2 && fieldValues.lastName.length < 25? "" : "Must be between 3-24 characters";
            }
        }

        if ('houseNumber' in fieldValues) {
            temp.houseNumber = fieldValues.houseNumber ? "" : "This field is required";
            if (temp.houseNumber === '') {
                temp.houseNumber = fieldValues.houseNumber.length > 2 && fieldValues.houseNumber.length < 25? "" : "Must be between 3-24 characters";
            }
        }

        if ('streetName' in fieldValues) {
            temp.streetName = fieldValues.streetName ? "" : "This field is required";
            if (temp.streetName === '') {
                temp.streetName = fieldValues.streetName.length > 2 && fieldValues.streetName.length < 51 ? "" : "Must be between 3-50 characters";
            }
        }

        if ('location' in fieldValues) {
            temp.location = fieldValues.location ? "" : "This field is required";
            if (temp.location === '') {
                temp.location = fieldValues.location.length > 2 && fieldValues.location.length < 25? "" : "Must be between 3-24 characters";
            }
        }

        if ('postcode' in fieldValues) {
            temp.postcode = fieldValues.postcode ? "" : "This field is required";
            if (temp.postcode === '') {
            temp.postcode = (/^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i).test(fieldValues.postcode) ? "" : "Enter valid postcode, contactact support if correct is blocked";
                
            }
        }

        if ('dateOfBirth' in fieldValues) {
            temp.dateOfBirth = fieldValues.dateOfBirth ? "" : "This field is required";
        }

        if ('password' in fieldValues) { 
            temp.password =  ((/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/).test(fieldValues.password)) && fieldValues.password === values.repeatPassword ? '' : 'Passwords must match and be length 8 with atleast one capital and one number or symbol.'
        }

        setErrors({
            ...temp
        })
        // console.log(errors)
        if (fieldValues == values) {
            return Object.values(temp).every(x => x === "");
        }
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, false, validation)

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log("Here")
        // console.log(validation())
        if (validation()) {
            values.inviteJWT = authData?.authDetails?.inviteJWT
            setPressed(true)
            dispatch(completeSignupFullCompany({ auth: auth?.token, values }))
        }
    } 

    return (
    <Box display='flex' width='100%' height='100%' justifyContent={'center'} alignContent='center' alignItems={'center'} justifyItems='center'>
        
        <Box  width='50%' minHeight='70%' bgcolor='#f2f0f0' boxShadow={3} justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center' borderRadius={3} p={5}>
            <Box display='flex' justifyContent='right'>
            <IconButton onClick={logout}>
              <LogoutIcon />
            </IconButton>
            </Box>
            <Form onSubmit={handleSubmit}>
            <Box >
                <Typography variant='h3' fontWeight={700} mb={2}>BACKSEAT FLEET</Typography>
            </Box>
            <Box >
                <Typography variant='h6' fontWeight={700} mb={2}>{}</Typography>
            </Box>
            <Box >
                <Typography mb={2}>Welcome Employee of {authData?.authDetails ? authData?.authDetails?.companyName : null}</Typography>
            </Box>
            <Box>
                <Typography  mb={2}>Let's finalize your setup!</Typography>
            </Box>
            
            <Box display='flex' justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                <Typography  mb={2} flexBasis='100%'>Before we activate your account you need fil in a few deatils and read and agree to our terms and conditions.</Typography>
            </Box>

            <Box>
                <Box>
                    <Typography>Your username will be used to log in with your companys name in the format <b>USERNAME.COMPANY</b> name. In this case the company aspect will be <b>{userDetails?.companyDotString?.companyDotString}</b> thus your username will be <b>USERNAME.{userDetails?.companyDotString?.companyDotString}</b></Typography>
                </Box>
                {
                !pressed ?
                <>
                <Box width='100%' display='flex'>
                    <Typography  mb={2} flexBasis='30%'>Username: </Typography>
                    <Controls.Input 
                            name="userName"
                            label="Username"
                            value={values.userName}
                            onChange={handleInputChange}
                            error={errors.userName}
                            disabled
                        />
                </Box>
                <Box width='100%' display='flex'>
                <Typography  mb={2} flexBasis='30%'>Full Name: </Typography>
                <Controls.Input 
                        name="firstName"
                        label="First Name"
                        value={values.firstName}
                        onChange={handleInputChange}
                        error={errors.firstName}
                    />
                    <Controls.Input 
                        name="lastName"
                        label="Last Name"
                        value={values.lastName}
                        onChange={handleInputChange}
                        error={errors.lastName}
                    />
                </Box>
                <Box width='100%' display='flex'>
                    <Typography  mb={2} flexBasis='30%'>D.O.B</Typography>
                    <Controls.Input 
                        name="dateOfBirth"
                        type='date'
                        value={values.dateOfBirth}
                        onChange={handleInputChange}
                        error={errors.dateOfBirth}
                    />
                </Box>
                <Box width='100%' display='flex'>
                <Typography  mb={2} flexBasis='30%'>Email Address</Typography>
                <Controls.Input 
                        name="emailAddress"
                        disabled={true}
                        width={'30%'}
                        value={values.emailAddress}
                        onChange={handleInputChange}
                        error={errors.emailAddress}
                    />
                </Box>
                <Box width='100%' display='flex'>
                <Typography  mb={2} flexBasis='30%'>Address</Typography>
                <Controls.Input 
                        name="houseNumber"
                        label="House Number/Name"
                        value={values.houseNumber}
                        onChange={handleInputChange}
                        error={errors.houseNumber}
                    />
                <Controls.Input 
                        name="streetName"
                        label="Street Name"
                        value={values.streetName}
                        onChange={handleInputChange}
                        error={errors.streetName}
                    />
                </Box>
                <Box width='100%' display='flex'>
                    <Typography  mb={2} flexBasis='30%'></Typography>
                    <Controls.Input 
                            name="location"
                            label="City/Town/Village"
                            value={values.location}
                            onChange={handleInputChange}
                            error={errors.location}
                        />
                    <Controls.Input 
                            name="postcode"
                            label="Postcode"
                            value={values.postcode}
                            onChange={handleInputChange}
                            error={errors.postcode}
                        />
                </Box>
                <Box width='100%' display='flex'>
                    <Typography  mb={2} flexBasis='30%'>Phone</Typography>
                    <Controls.Input 
                        name="telNum"
                        label="Phone Number (Optional)"
                        value={values.telNum}
                        onChange={handleInputChange}
                        error={errors.telNum}
                    />
                </Box>
                <Box width='100%' display='flex'>
                <Typography  mb={2} flexBasis='30%'></Typography>

                <Controls.Input
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleInputChange}
                    autoComplete='new-password'
                    error={errors.password}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword === false ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    
                />
                <Controls.Input
                    name="repeatPassword"
                    label="Repeat Password"
                    autoComplete='new-password'
                    type={showPassword ? 'text' : 'password'}
                    value={values.repeatPassword}
                    onChange={handleInputChange}
                    error={errors.repeatPassword}
                    
                />
                </Box>
                <Box width='100%' display='flex' justifyContent='center'>
                    <Button onClick={() => setOpenTerms(true)}>Show Terms and Conditions</Button>
                </Box>
                <Box width='100%' display='flex' justifyContent='center'>
                <Controls.Checkbox 
                            name="agreeTerms"
                            label="I Agree to Terms and Conditions"
                            value={values.agreeTerms}
                            onChange={handleInputChange}
                            error={errors.agreeTerms}
                        />
                </Box>

                <Box width='100%' display='flex' justifyContent='center'>
                    <Button variant='outlined' type='submit'>Submit</Button>
                </Box>
                </>
                :
                <CircularProgress />
                }
                
            </Box>

        </Form>

        </Box>
        <TermsAndCondModal openTerms={openTerms} setOpenTerms={setOpenTerms}/>
    </Box>
  )
}



export default SUFullUser