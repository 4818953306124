import { START_COMPANY_DETAILS_LOADING, END_COMPANY_DETAILS_LOADING, FETCH_COMPANY_DETAILS } from '../constants/actionTypes';

export default (state = {isLoading: true, companyDetails: []}, action) => {
    switch (action.type) {
        case START_COMPANY_DETAILS_LOADING:
            return { ...state, isLoading: true };
        case END_COMPANY_DETAILS_LOADING:
            return { ...state, isLoading: false };
        case FETCH_COMPANY_DETAILS:
            return { ...state, companyDetails: action?.payload};
        default:
            return state;
    }
}