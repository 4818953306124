import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'


export const GettingStartedContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #22303c;
    padding: 20px

    ${'' /* @media screen and (max-width: 768px) {
        height: 1100px;
    }

    @media screen and (max-width: 480px) {
        height: 1400px;
    } */}
`;

export const GettingStartedWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;

    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const GettingStartedCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`;

export const GettingStartedIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;

export const GettingStartedH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;
    
    @media screen and (max-width: 480px) {
        font-size: 2rem
    }   
`;

export const GettingStartedH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`;

export const GettingStartedP = styled.p`
    font-size: 1rem;
    text-align: center;
    padding: 10px;
`;

export const GettingStartedClosingH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-top: 64px;
    
    @media screen and (max-width: 480px) {
        font-size: 2rem
    }  
`

export const TopLine = styled.p`
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: upppercase;
    margin-bottom: 16px
`;

export const HomeBtnWrapper = styled.div`
    margin: 32px;
    dislay: flex;
    flex-direction: column;
    align: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px
`;