import { START_ORDER_DETAILS_LOADING, END_ORDER_DETAILS_LOADING, GET_ORDER_DETAILS, UPDATE_ORDER_DETAILS, DELETE_ORDER } from '../constants/actionTypes';

export default (state = { isLoading: true, orderDetails: [] }, action) => {
    switch (action.type) {
        case START_ORDER_DETAILS_LOADING:
            return { ...state, isLoading: true };
        case END_ORDER_DETAILS_LOADING:
            return { ...state, isLoading: false };
        case GET_ORDER_DETAILS:
            return { ...state, orderDetails: action.payload };
        case UPDATE_ORDER_DETAILS:
            return { ...state, orderDetails: action.payload };
        case DELETE_ORDER:
            return {...state, orderDetails: action.payload};
        default:
            return state;
    }
};