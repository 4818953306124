export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH = 'FETCH';
export const DEACTIVATE = 'DEACTIVATE';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const NEWUSER = 'NEWUSER';
export const REDIRECT_USER = 'REDIRECT_USER';
export const START_LOGIN_LOADING = 'START_LOGIN_LOADING';
export const END_LOGIN_LOADING = 'END_LOGIN_LOADING';

// NEW STRUCTURE
export const START_LOADING_LICENCE_ENDPOINT = 'START_LOADING_LICENCE_ENDPOINT';
export const END_LOADING_LICENCE_ENDPOINT = 'END_LOADING_LICENCE_ENDPOINT';
export const POST_LICENCE_ENDPOINT_DATA = 'POST_LICENCE_ENDPOINT_DATA';
export const FETCH_LICENCE_ENDPOINT_DATA = 'FETCH_LICENCE_ENDPOINT_DATA';
export const CLEAR_LICENCE_ENDPOINT_DATA = 'CLEAR_LICENCE_DVLA_DATA';

export const START_LOADING_COMPANY_VECHILES_ENDPOINT = 'START_LOADING_COMPANY_VECHILES_ENDPOINT';
export const END_LOADING_COMPANY_VECHILES_ENDPOINT = 'END_LOADING_COMPANY_VECHILES_ENDPOINT';
export const POST_COMPANY_VECHILES_ENDPOINT_DATA = 'POST_COMPANY_VECHILES_ENDPOINT_DATA';
export const FETCH_COMPANY_VECHILES_ENDPOINT_DATA = 'FETCH_COMPANY_VECHILES_ENDPOINT_DATA';
export const CLEAR_COMPANY_VECHILES_ENDPOINT_DATA = 'CLEAR_COMPANY_VECHILES_DVLA_DATA';

export const START_LOADING_GET_COMPANY_VECHILES_ENDPOINT = 'START_LOADING_GET_COMPANY_VECHILES_ENDPOINT';
export const END_LOADING_GET_COMPANY_VECHILES_ENDPOINT = 'END_LOADING_GET_COMPANY_VECHILES_ENDPOINT';
export const POST_GET_COMPANY_VECHILES_ENDPOINT_DATA = 'POST_GET_COMPANY_VECHILES_ENDPOINT_DATA';
export const FETCH_GET_COMPANY_VECHILES_ENDPOINT_DATA = 'FETC_GETH_COMPANY_VECHILES_ENDPOINT_DATA';
export const CLEAR_GET_COMPANY_VECHILES_ENDPOINT_DATA = 'CLEAR_GET_COMPANY_VECHILES_DVLA_DATA';

export const START_LOADING_COMPANY_VECHILE_ENDPOINT = 'START_LOADING_COMPANY_VECHILE_ENDPOINT';
export const END_LOADING_COMPANY_VECHILE_ENDPOINT = 'END_LOADING_COMPANY_VECHILE_ENDPOINT';
export const POST_COMPANY_VECHILE_ENDPOINT_DATA = 'POST_COMPANY_VECHILE_ENDPOINT_DATA';
export const FETCH_COMPANY_VECHILE_ENDPOINT_DATA = 'FETCH_COMPANY_VECHILE_ENDPOINT_DATA';
export const CLEAR_COMPANY_VECHILE_ENDPOINT_DATA = 'CLEAR_COMPANY_VECHILE_DVLA_DATA';

export const START_LOADING_GET_COMPANY_VECHILE_ENDPOINT = 'START_LOADING_GET_COMPANY_VECHILE_ENDPOINT';
export const END_LOADING_GET_COMPANY_VECHILE_ENDPOINT = 'END_LOADING_GET_COMPANY_VECHILE_ENDPOINT';
export const POST_GET_COMPANY_VECHILE_ENDPOINT_DATA = 'POST_GET_COMPANY_VECHILE_ENDPOINT_DATA';
export const FETCH_GET_COMPANY_VECHILE_ENDPOINT_DATA = 'FETC_GETH_COMPANY_VECHILE_ENDPOINT_DATA';
export const CLEAR_GET_COMPANY_VECHILE_ENDPOINT_DATA = 'CLEAR_GET_COMPANY_VECHILE_DVLA_DATA';

export const START_LOADING_PERSONAL_VECHILES_ENDPOINT = 'START_LOADING_PERSONAL_VECHILES_ENDPOINT';
export const END_LOADING_PERSONAL_VECHILES_ENDPOINT = 'END_LOADING_PERSONAL_VECHILES_ENDPOINT';
export const POST_PERSONAL_VECHILES_ENDPOINT_DATA = 'POST_PERSONAL_VECHILES_ENDPOINT_DATA';
export const FETCH_PERSONAL_VECHILES_ENDPOINT_DATA = 'FETCH_PERSONAL_VECHILES_ENDPOINT_DATA';
export const CLEAR_PERSONAL_VECHILES_ENDPOINT_DATA = 'CLEAR_PERSONAL_VECHILES_DVLA_DATA';

export const START_LOADING_GET_PERSONAL_VECHILES_ENDPOINT = 'START_LOADING_GET_PERSONAL_VECHILES_ENDPOINT';
export const END_LOADING_GET_PERSONAL_VECHILES_ENDPOINT = 'END_LOADING_GET_PERSONAL_VECHILES_ENDPOINT';
export const POST_GET_PERSONAL_VECHILES_ENDPOINT_DATA = 'POST_GET_PERSONAL_VECHILES_ENDPOINT_DATA';
export const FETCH_GET_PERSONAL_VECHILES_ENDPOINT_DATA = 'FETC_GETH_PERSONAL_VECHILES_ENDPOINT_DATA';
export const CLEAR_GET_PERSONAL_VECHILES_ENDPOINT_DATA = 'CLEAR_GET_PERSONAL_VECHILES_DVLA_DATA';

export const START_LOADING_PERSONAL_VECHILE_ENDPOINT = 'START_LOADING_PERSONAL_VECHILE_ENDPOINT';
export const END_LOADING_PERSONAL_VECHILE_ENDPOINT = 'END_LOADING_PERSONAL_VECHILE_ENDPOINT';
export const POST_PERSONAL_VECHILE_ENDPOINT_DATA = 'POST_PERSONAL_VECHILE_ENDPOINT_DATA';
export const FETCH_PERSONAL_VECHILE_ENDPOINT_DATA = 'FETCH_PERSONAL_VECHILE_ENDPOINT_DATA';
export const CLEAR_PERSONAL_VECHILE_ENDPOINT_DATA = 'CLEAR_PERSONAL_VECHILE_DVLA_DATA';

export const START_LOADING_GET_PERSONAL_VECHILE_ENDPOINT = 'START_LOADING_GET_PERSONAL_VECHILE_ENDPOINT';
export const END_LOADING_GET_PERSONAL_VECHILE_ENDPOINT = 'END_LOADING_GET_PERSONAL_VECHILE_ENDPOINT';
export const POST_GET_PERSONAL_VECHILE_ENDPOINT_DATA = 'POST_GET_PERSONAL_VECHILE_ENDPOINT_DATA';
export const FETCH_GET_PERSONAL_VECHILE_ENDPOINT_DATA = 'FETC_GETH_PERSONAL_VECHILE_ENDPOINT_DATA';
export const CLEAR_GET_PERSONAL_VECHILE_ENDPOINT_DATA = 'CLEAR_GET_PERSONAL_VECHILE_DVLA_DATA';

export const START_LOADING_EMPLOYEES_ENDPOINT = 'START_LOADING_EMPLOYEES_ENDPOINT'
export const END_LOADING_EMPLOYEES_ENDPOINT = 'END_LOADING_EMPLOYEES_ENDPOINT'
export const POST_EMPLOYEES_ENDPOINT_DATA = 'POST_EMPLOYEES_ENDPOINT_DATA'
export const FETCH_EMPLOYEES_ENDPOINT_DATA = 'FETCH_EMPLOYEES_ENDPOINT_DATA'
export const CLEAR_EMPLOYEES_ENDPOINT_DATA = 'CLEAR_EMPLOYEES_ENDPOINT_DATA'

export const START_LOADING_EMPLOYEE_ENDPOINT = 'START_LOADING_EMPLOYEE_ENDPOINT';
export const END_LOADING_EMPLOYEE_ENDPOINT = 'END_LOADING_EMPLOYEE_ENDPOINT';
export const POST_EMPLOYEE_ENDPOINT_DATA = 'POST_EMPLOYEE_ENDPOINT_DATA';
export const FETCH_EMPLOYEE_ENDPOINT_DATA = 'FETCH_EMPLOYEE_ENDPOINT_DATA';
export const CLEAR_EMPLOYEE_ENDPOINT_DATA = 'CLEAR_EMPLOYEE_ENDPOINT_DATA';

export const START_LOADING_COMPANY_SIGNUP_ENDPOINT = 'START_LOADING_COMPANY_SIGNUP_ENDPOINT';
export const END_LOADING_COMPANY_SIGNUP_ENDPOINT = 'END_LOADING_COMPANY_SIGNUP_ENDPOINT';
export const POST_COMPANY_SIGNUP_ENDPOINT_DATA = 'POST_COMPANY_SIGNUP_ENDPOINT_DATA';
export const FETCH_COMPANY_SIGNUP_ENDPOINT_DATA = 'FETCH_COMPANY_SIGNUP_ENDPOINT_DATA';
export const CLEAR_COMPANY_SIGNUP_ENDPOINT_DATA = 'CLEAR_COMPANY_SIGNUP_ENDPOINT_DATA';

export const START_LOADING_MANAGER_DETAILS_ENDPOINT = 'START_LOADING_MANAGER_DETAILS_ENDPOINT';
export const END_LOADING_MANAGER_DETAILS_ENDPOINT = 'END_LOADING_COMPANY_SIGNUP_ENDPOINT';
export const POST_MANAGER_DETAILS_ENDPOINT_DATA = 'POST_MANAGER_DETAILS_ENDPOINT_DATA';
export const FETCH_MANAGER_DETAILS_ENDPOINT_DATA = 'FETCH_MANAGER_DETAILS_ENDPOINT_DATA';
export const CLEAR_MANAGER_DETAILS_ENDPOINT_DATA = 'CLEAR_MANAGER_DETAILS_ENDPOINT_DATA';

export const START_LOADING_COMPANY_MANAGER_ENDPOINTS = 'START_LOADING_COMPANY_MANAGER_ENDPOINTS';
export const END_LOADING_COMPANY_MANAGER_ENDPOINTS = 'END_LOADING_COMPANY_MANAGER_ENDPOINTS';
export const POST_COMPANY_MANAGER_ENDPOINTS_DATA = 'POST_COMPANY_MANAGER_ENDPOINTS_DATA';
export const FETCH_COMPANY_MANAGER_ENDPOINTS_DATA = 'FETCH_COMPANY_MANAGER_ENDPOINTS_DATA';
export const CLEAR_COMPANY_MANAGER_ENDPOINTS_DATA = 'CLEAR_COMPANY_MANAGER_ENDPOINTS_DATA';

export const START_LOADING_COMPANY_ORDERS_ENDPOINT = 'START_LOADING_COMPANY_ORDERS_ENDPOINT';
export const END_LOADING_COMPANY_ORDERS_ENDPOINT = 'END_LOADING_COMPANY_ORDERS_ENDPOINT';
export const POST_COMPANY_ORDERS_ENDPOINT_DATA = 'POST_COMPANY_ORDERS_ENDPOINT_DATA';
export const FETCH_COMPANY_ORDERS_ENDPOINT_DATA = 'FETCH_COMPANY_ORDERS_ENDPOINT_DATA';
export const CLEAR_COMPANY_ORDERS_ENDPOINT_DATA = 'CLEAR_COMPANY_ORDERS_ENDPOINT_DATA';


export const CLEAR_COST_CENTRES = 'CLEAR_COST_CENTRES';

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_USER = 'FETCH_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_USER = 'CREATE_USER';
export const START_USER_LOADING = 'START_USER_LOADING';
export const END_USER_LOADING = 'END_USER_LOADING';
export const FETCH_COMPANY_USERS = 'FETCH_COMPANY_USERS';
export const FETCH_COMPANY_USERS_ALL = 'FETCH_COMPANY_USERS_ALL';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const FIRST_LICENCE_CHECK = 'FIRST_LICENCE_CHECK';

export const START_USER_DETAILS_LOADING = 'START_USER_DETAILS_LOADING';
export const END_USER_DETAILS_LOADING = 'END_USER_DETAILS_LOADING';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_COSTCENTRE_PERMS_DETAILS = 'UPDATE_COSTCENTRE_PERMS_DETAILS';
export const GET_USER_LICENCE_DETAILS = 'GET_USER_LICENCE_DETAILS';

export const FETCH_MESSAGE_INBOX = 'FETCH_MESSAGE_INBOX';

export const INVITE_USER = 'INVITE_USER';
export const START_INVITE_LOADING = 'START_INVITE_LOADING';
export const END_INVITE_LOADING = 'END_INVITE_LOADING';
export const INVITE_NEW_USER = 'INVITE_NEW_USER';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_ALL_COMPANIES = 'FETCH_ALL_COMPANIES'
export const GET_COMPANY_COST_CENTRES = 'GET_COMPANY_COST_CENTRES';
export const START_COMPANIES_LOADING = 'START_COMPANIES_LOADING';
export const END_COMPANIES_LOADING = 'END_COMPANIES_LOADING';
export const FETCH_COMPANIES_AND_CRS = 'FETCH_COMPANIES_AND_CRS';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';

export const START_COMPANY_ADD_LOADING = 'START_COMPANY_ADD_LOADING';
export const END_COMPANY_ADD_LOADING = 'ENDCOMPANY_ADD_LOADING';
export const FORCE_ADD_COMPANY = 'FORCE_ADD_COMPANY';
export const INVITE_COMPANY = 'INVITE_COMAPNY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const CLEAR_ADD_COMPANY_DATA = 'CLEAR_ADD_COMPANY_DATA';
export const INVITE_NEW_COMPANY = 'INVITE_NEW_COMPANY';

export const FETCH_COMPANY_DETAILS = 'FETCH_COMPANY_DETAILS';
export const START_COMPANY_DETAILS_LOADING = 'START_COMPANY_DETAILS_LOADING';
export const END_COMPANY_DETAILS_LOADING = 'END_COMPANY_DETAILS_LOADING';


export const CREATE_TICKET = 'CREATE_TICKET';
export const FETCH_ALL_TICKETS = 'FETCH_ALL_TICKETS';
export const FETCH_TICKET = 'FETCH_TICKET';
export const START_TICKET_LOADING = 'START_TICKET_LOADING';
export const END_TICKET_LOADING = 'END_TICKET_LOADING';

export const FETCH_ALL_MESSAGES = 'FETCH_ALL_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const START_MESSAGE_LOADING = 'START_MESSAGE_LOADING';
export const END_MESSAGE_LOADING = 'END_MESSAGE_LOADING';

export const START_ORDER_LOADING = 'START_ORDER_LOADING';
export const END_ORDER_LOADING = 'END_ORDER_LOADING';
export const FETCH_ALL_ORDERS = 'GET_ORDERS';
export const FETCH_ORDER = 'GET_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const MANUAL_CREATE_ORDER = 'MANUAL_CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

export const START_CARS_LOADING = 'START_CARS_LOADING';
export const END_CARS_LOADING = 'END_CARS_LOADING';
export const FETCH_ALL_GREY = 'FETCH_ALL_GREY';
export const FETCH_ALL_CORP = 'FETCH_ALL_CORP';
export const FETCH_USERS_CARS = 'FETCH_USERS_CARS';
export const FETCH_GREY = 'FETCH_GREY';
export const FETCH_CORP = 'FETCH_CORP';
export const CREATE_GREY = 'CREATE_GREY';
export const CREATE_CORP = 'CREATE_CORP';
export const UPDATE_GREY = 'UPDATE_GREY';
export const UPDATE_CORP = 'UPDATE_CORP';
export const DELETE_GREY = 'DELETE_GREY';
export const DELETE_CORP = 'DELETE_CORP';

export const START_CARS_DETAILS_LOADING = 'START_CARS_DETAILS_LOADING';
export const END_CARS_DETAILS_LOADING = 'END_CARS_DETAILS_LOADING';
export const FETCH_CAR_DETAILS = 'FETCH_CAR_DETAILS';
export const FETCH_CORP_CAR_DETAILS = 'FETCH_CORP_CAR_DETAILS';
export const FETCH_GREY_CAR_DETAILS = 'FETCH_GREY_CAR_DETAILS';
export const UPDATE_CORP_MILAGE = 'UPDATE_CORP_MILAGE';
export const UPDATE_GREY_MILAGE = 'UPDATE_GREY_MILAGE';
export const UPDATE_CAR_DETAILS = 'UPDATE_CAR_DETAILS';
export const UPDATE_CORP_SERVICE = 'UPDATE_CORP_SERVICE';
export const UPDATE_CORP_ACCIDENT = 'UPDATE_CORP_ACCIDENT';
export const UPDATE_CORP_INSURANCE= 'UPDATE_CORP_INSURANCE';

export const FETCH_ALL_COST_CENTRES = 'FETCH_ALL_COST_CENTRES';
export const FETCH_COST_CENTRE = 'FETCH_COST_CENTRE';
export const CREATE_COST_CENTRE = 'CREATE_COST_CENTRE';
export const UPDATE_COST_CENTRE = 'UPDATE_COST_CENTRE';
export const DELETE_COST_CENTRE = 'DELETE_COST_CENTRE';
export const START_COST_CENTRE_LOADING = 'START_COST_CENTRE_LOADING';
export const END_COST_CENTRE_LOADING = 'END_COST_CENTRE_LOADING';

export const START_ORDER_DETAILS_LOADING = 'START_ORDER_DETAILS_LOADING';
export const END_ORDER_DETAILS_LOADING = 'END_ORDER_DETAILS_LOADING';
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';
export const DELETE_ORDER = 'DELETE_ORDER';
export const COMPANY_ORDER_CREATION = 'COMPANY_ORDER_CREATION';

export const START_DASHBOARD_LOADING = 'START_DASHBOARD_LOADING';
export const END_DASHBOARD_LOADING = 'END_DASHBOARD_LOADING';
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const CREATE_NEW_DASHBOARD = 'CREATE_NEW_DASHBOARD';

export const START_UPDATE_ACTIONS_LOADING = 'START_UPDATE_ACTIONS_LOADING';
export const END_UPDATE_ACTIONS_LOADING = 'END_UPDATE_ACTIONS_LOADING';
export const UPDATE_ALL_CARS = 'UPDATE_ALL_CARS';
export const UPDATE_CARS = 'UPDATE_CARS';
export const UPDATE_ALL_LICENCES = 'UPDATE_ALL_LICENCES';
export const UPDATE_LICENCE = 'UPDATE_LICENCE';

export const SEND_EMAIL = 'SEND_EMAIL';

export const START_TICKET_DETAILS_LOADING = 'START_TICKET_DETAILS_LOADING';
export const END_TICKET_DETAILS_LOADING = 'END_TICKET_DETAILS_LOADING';
export const GET_TICKET_DETAILS = 'GET_TICKET_DETAILS';
export const UPDATE_TICKET_DETAILS = 'UPDATE_TICKET_DETAILS';
export const CLEAR_TICKET_DETAILS = 'CLEAR_TICKET_DETAILS';

export const START_HISTORY_LOADING = 'START_HISTORY_LOADING';
export const END_HISTORY_LOADING = 'END_HISTORY_LOADING';
export const FETCH_ALL_HISTORY = 'FETCH_ALL_HISTORY';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const FETCH_HISTORY_DETAILS = 'FETCH_HISTORY_DETAILS';

export const START_TOKEN_LOADING = 'START_TOKEN_LOADING';
export const END_TOKEN_LOADING = 'END_TOKEN_LOADING';
export const FETCH_TOKEN_AMOUNTS = 'FETCH_TOKEN_AMOUNTS';

export const START_INVOICE_LOADING = 'START_INVOICE_LOADING';
export const END_INVOICE_LOADING = 'END_INVOICE_LOADING';
export const FETCH_INVOICE_AMOUNTS = 'FETCH_INVOICE_AMOUNTS';

export const START_LOADING_CARS_CSV = 'START_LOADING_CARS_CSV';
export const END_LOADING_CARS_CSV = 'END_LOADING_CARS_CSV';
export const FETCH_CARS_CSV = 'FETCH_CARS_CSV';
export const CLEAR_CARS_CSV = 'CLEAR_CARS_CSV';

export const START_LOADING_SERVICE_CSV = 'START_LOADING_SERVICE_CSV';
export const END_LOADING_SERVICE_CSV = 'END_LOADING_SERVICE_CSV';
export const FETCH_LOADING_SERVICE_CSV = 'FETCH_LOADING_SERVICE_CSV';
export const CLEAR_SERVICE_CSV = 'CLEAR_SERVICE_CSV';

export const START_LOADING_ORDER_HISTORY_CSV = 'START_LOADING_ORDER_HISTORY_CSV';
export const END_LOADING_ORDER_HISTORY_CSV = 'END_LOADING_ORDER_HISTORY_CSV';
export const FETCH_ORDER_HISTORY_CSV = 'FETCH+ORDER_HISTORY_CSV'
export const CLEAR_ORDER_HISTORY_CSV = 'CLEAR_ORDER_HISTORY_CSV';

export const START_LOADING_TOKEN_HISTORY_CSV = 'START_LOADING_TOKEN_HISTORY_CSV';
export const END_LOADING_TOKEN_HISTORY_CSV = 'END_LOADING_TOKEN_HISTORY_CSV';
export const FETCH_TOKEN_HISTORY_CSV = 'FETCH_TOKEN_HISTORY_CSV';
export const CLEAR_TOKEN_CSV = 'CLEAR_TOKEN_CSV';

export const START_LOADING_LICENCE_CSV = 'START_LOADING_LICENCE_CSV';
export const END_LOADING_LICENCE_CSV = 'END_LOADING_LICENCE_CSV';
export const FETCH_LICENCE_CSV = 'FETCH_LICENCE_CSV';
export const CLEAR_LICENCE_CHECK_CSV = 'CLEAR_LICENCE_CHECK_CSV';

export const START_LOADING_DVLA_LICENCE_CHECK = 'START_LOADING_DVLA_LICENCE_CHECK';
export const END_LOADING_DVLA_LICENCE_CHECK = 'END_LOADING_DVLA_LICENCE_CHECK';
export const FETCH_TEST_DATA = 'FETCH_TEST_DATA';
export const CLEAR_LICENCE_DVLA_DATA = 'CLEAR_LICENCE_DVLA_DATA';

export const START_PERSONAL_VEHICLES_LOADING = 'START_PERSONAL_VEHICLES_LOADING';
export const END_PERSONAL_VEHICLES_LOADING = 'END_PERSONAL_VEHICLES_LOADING';
export const FETCH_PERSONAL_VEHICLES = 'FETCH_PERSONAL_VEHICLES';
export const ADD_PERSONAL_VEHICLE = 'ADD_PERSONAL_VEHICLE';
export const CLEAR_PERSONAL_VEHICLES = 'CLEAR_PERSONAL_VEHICLES';
export const ADD_PERSONAL_COMPANY_VEHICLE = 'ADD_PERSONAL_COMPANY_VEHICLE';

export const START_PERSONAL_VEHICLE_LOADING = 'START_PERSONAL_VEHICLE_LOADING';
export const END_PERSONAL_VEHICLE_LOADING = 'END_PERSONAL_VEHICLE_LOADING';
export const FETCH_PERSONAL_VEHICLE = 'FETCH_PERSONAL_VEHICLE';
export const FETCH_PERSONAL_COMPANY_VEHICLES = 'FETCH_PERSONAL_COMPANY_VEHICLES';
export const CLEAR_PERSONAL_VEHICLE = 'CLEAR_PERSONAL_VEHICLE';

export const DISABLE_PERSONAL_VEHICLE = 'DISABLE_PERSONAL_VEHICLE';
export const DELETE_PERSONAL_VEHICLE = 'DELETE_PERSONAL_VEHICLE';
export const ENABLE_PERSONAL_VEHICLE = 'ENABLE_PERSONAL_VEHICLE';
export const FETCH_INSURANCE_DOCUMENT = 'FETCH_INSURANCE_DOCUMENT';
export const UPDATE_INSURANCE_DOCUMENT = 'UPDATE_INSURANCE_DOCUMENT';

export const TEST_ACCEPT_DECLINE_VEHICLE = 'TEST_ACCEPT_DECLINE_VEHICLE';

export const ADD_MILAGE_PERSONAL_COMPANY_VEHICLE = 'ADD_MILAGE_PERSONAL_COMPANY_VEHICLE';
export const ADD_MILAGE_PERSONAL_VEHICLE = 'ADD_MILAGE_PERSONAL_VEHICLE';
  
export const DELETE_MILAGE_PERSONAL_VEHICLE = 'DELETE_MILAGE_PERSONAL_VEHICLE';
export const DELETE_MILAGE_PERSONAL_COMPANY_VEHICLE = 'DELETE_MILAGE_PERSONAL_COMPANY_VEHICLE';

export const MANUAL_LICENCE_CHECK = 'MANUAL_LICENCE_CHECK';
export const WITHDRAW_LICENCE_RECHECK = 'WITHDRAW_LICENCE_RECHECK';
export const RENEW_LICENCE_RECHECK = 'RENEW_LICENCE_RECHECK';

export const UPDATE_COMPANY_SERVICE_HISTORY = 'UPDATE_COMPANY_SERVICE_HISTORY';
export const UPDATE_CORPORATE_VEHICLE_ASSIGNED = 'UPDATE_CORPORATE_VEHICLE_ASSIGNED';

export const JWT_USER_SIGN_UP = 'JWT_USER_SIGN_UP';
export const JWT_MANAGE_SIGN_UP = 'JWT_MANAGE_SIGN_UP';
export const START_JWT_LOADING = 'START_JWT_LOADING';
export const FETCH_JWT_DATA = 'FETCH_JWT_DATA';
export const END_JWT_LOADING= 'END_JWT_LOADING';
export const CLEAN_JWT_SIGNUP = 'CLEAN_JWT_SIGNUP';
export const COMPLETE_FULL_COMPANY_SIGNUP = 'COMPLETE_FULL_COMPANY_SIGNUP';
export const CLEAR_USERS= 'CLEAR_USERS';
export const SIGNUP_AUTH = 'SIGNUP_AUTH';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';

export const SEND_DIRECT_MESSAGE = 'SEND_DIRECT_MESSAGE';
export const START_DIRECT_MESSAGE_LOADING = 'START_DIRECT_MESSAGE_LOADING';
export const END_DIRECT_MESSAGE_LOADING = 'END_DIRECT_MESSAGE_LOADING';
export const CLEAR_DIRECT_MESSAGE = 'CLEAR_DIRECT_MESSAGE';

export const START_NOTIFICATIONS_LOADING = 'START_NOTIFICATIONS_LOADING';
export const END_NOTIFICATIONS_LOADING = 'END_NOTIFICATIONS_LOADING';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';

// POLCIES END POINTS
export const FETCH_POLICES = 'FETCH_POLICES';
export const END_POLICIES_LOADING = 'END_POLICIES_LOADING';
export const START_POLICES_LOADING = 'START_POLICES_LOADING';

export const START_EMPLOYEE_SETTINGS_LOADING = 'START_EMPLOYEE_SETTINGS_LOADING'
export const END_EMPLOYEE_SETTINGS_LOADING = 'END_EMPLOYEE_SETTINGS_LOADING'
export const FETCH_EMPLOYEE_SETTINGS = 'FETCH_EMPLOYEE_SETTINGS'