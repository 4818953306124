import { FilePresent } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup as MuiRadioGroup } from '@mui/material';
import React from 'react'

const RadioGroup = (props) => {
    const { name, label, value, onChange, items, color='black', error=null, fontSize=15, row=true, column=false } = props;

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <MuiRadioGroup 
            row={row}
            // column={column}
            sx={{ color: color,
            '&.Mui-checked': {
                color: 'pink',
            }, }}
            name={name}
            value={value}
            onChange={onChange}  
            >
            {
                items.map(
                    (item, index) => (
                        <FormControlLabel key={item.id} value={item.id} control={<Radio 
                            sx={{
                                color: 'pink',
                                '&.Mui-checked': {
                                    color: 'green'
                                }
                            }}
                        />} label={item.title} />
                    )
                )
            }
            
            </MuiRadioGroup>
            {error ? 
        <FormHelperText sx={{ color: 'red', fontSize: fontSize }}>{error}</FormHelperText>
        :
        null
        }
        
        </FormControl>
    )
}

export default RadioGroup