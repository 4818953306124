import React, { useEffect, useState } from 'react'
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Input from './Input';
import { useDispatch, useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import useAuth from '../../hooks/useAuth';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useNavigate, Link, useLocation } from 'react-router-dom';
import useStyles from './styles';
import { signup, signin } from '../../actions/authData';

// FIGURE OUT AND ADD REQUIRED TO FIELDS
const initialState = { companyName: '', companyDotString: '', companyContactName: '', companyNumber: '', initPurchase: '', companyEmailAddress: '',  companyPhoneNumber: '', userName: '', password: '', confirmPassword: ''};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Auth = ( { isSignupPage } ) => {
  const [open, setOpen] = React.useState(false);

  const { setAuth } = useAuth({});

  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || '/';

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(isSignupPage);
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const { isLoading, authData } = useSelector((state) => state.authData);
  //const [Loading, setLoading] = useState(false);
  console.log(authData)
  useEffect(() => {
    if(authData?.message === "Login failure") {
      // console.log("Triggered Cond")
      handlePopupFailure();
    } else if  (authData?.message === "Login Success") {
      setAuth(authData?.authDetails)
      // console.log(authData?.authDetails)
      console.log(from)
      from = location.state?.from?.pathname ||
      (authData?.authDetails?.role === 'user' ? '/User/Dashboard' :
      authData?.authDetails?.role === 'manager' ? '/Manager/Dashboard' :
      authData?.authDetails?.role === 'company' ? '/Company/Dashboard' :
      authData?.authDetails?.role === 'site' ? '/Admin/Dashboard' :
      '/');
      navigate(from, { replace: true });
      // navigate(authData?.authDetails?.targetLocation)
    }
  },[authData])

  const handlePopupFailure = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(isSignup) {
      dispatch(signup(formData, navigate));


    } else {
      dispatch(signin(formData, navigate));

      


    };
    
  };

  // useEffect(() => {
  //   setLoading(isLoading)
  // }, [isLoading])

  

  const handleChange = (e) => {
    setFormData({ ...formData,  [e.target.name]: e.target.value })
  };

  const swtichMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    
    handleShowPassword(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">{isSignup ? 'Sign Up' : 'Sign In'}</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacingt={2}>
            {
              isSignup && (
                <>
                  <Input name="companyName" label="Company Name" handleChange={handleChange} autoFocus half />
                  <Input name="companyDotString" label="Login ending, e.g, .companyX" handleChange={handleChange} half />
                  <Input name="companyContactName" label="Company Contact Name" handleChange={handleChange} half />
                  <Input name="companyNumber" label="Business Number" handleChange={handleChange} half />
                  <Input name="initPurchase" label="Initial Token Purchase" handleChange={handleChange} half />
                  <Input name="companyEmailAddress" label="Company Email" handleChange={handleChange} half type="email"/>
                  <Input name="companyPhoneNumber" label="Company Phone Number" handleChange={handleChange} half/>
                </>
              )}
              <Input name="userName" label="User Name" handleChange={handleChange} failed={authData?.message === "Login failure" ? "Username or password inccorect, please try again. Too many failed attempts will temporarly lock login attempts." : null} error={authData?.message === "Login failure" ? true : false}/>
              <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword}/>
              { isSignup && <Input name="confirmPassword" label="Confirm Password" handleChange={handleChange} type="password"/> }
          </Grid>

          <LoadingButton
          fullWidth 
          variant="contained" 
          color="primary" 
          className={classes.submit}
          type="submit"
          autoComplete={'on'}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          { isSignup ? 'Sign Up' : 'Sign In'}
        </LoadingButton>
          
          <Grid container justifyContent="flex-end">
            <Grid item>
                {/* <Button onClick={swtichMode}>{ isSignup ? 'Already a member? Sign In' : 'Interested in joining? Sign Up' }</Button> */}
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={authData?.message === "Login failure" ? "error" : authData?.message === "Login Success" ? "success" : "warning"} sx={{ width: '100%' }}>
        {authData?.message === "Login failure" ? "Login Failed" : authData?.message === "Login Success" ? "Login Success" : "Unknown Error"}
        </Alert>
      </Snackbar>

    </Container>
  ) 
};

export default Auth