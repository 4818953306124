import { START_NOTIFICATIONS_LOADING, END_NOTIFICATIONS_LOADING, FETCH_NOTIFICATIONS } from '../constants/actionTypes';


export default (state = { isLoading: true, notifications: [] }, action) => {
    switch (action.type) {
        case START_NOTIFICATIONS_LOADING:
            return { ...state, isLoading: true };
        case END_NOTIFICATIONS_LOADING:
            return { ...state, isLoading: false };
        case FETCH_NOTIFICATIONS:
            return { ...state, notifications: action.payload}
        default:
            return state;
    }
};
