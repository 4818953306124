import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';

export function useForm(initialFValues, validateOnChange=false, validation) {
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});

    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]:value
        })
        if(validateOnChange) 
            validation({[name]:value})
        
    };

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({});
    };

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm

    }
}

// const useStyles = makeStyles(theme => ({
//     root:{
//         '& .MuiFormControl-root':{
//             width: '97%',
//             padding: theme.spacing(1)
            
//         }
//     }
    //const classes = useStyles();
    // })) className={classes.root} 

export function Form(props) {
    const { children, ...other } = props; 

    return (
        <form  autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}





