import * as api from '../api';
import { START_JWT_LOADING, END_JWT_LOADING, FETCH_JWT_DATA, JWT_MANAGE_SIGN_UP, JWT_USER_SIGN_UP, CLEAN_JWT_SIGNUP } from '../constants/actionTypes';

export const getJWTData = (details) => async (dispatch) => {
    try {
        dispatch({ type: START_JWT_LOADING });
        // console.log("GOT TO ACTIONS - getUsers");
        const { data } = await api.getJWTData(details);
        
        dispatch({type: FETCH_JWT_DATA, payload: data});
    } catch (error) {
        // console.log(error.message);
        dispatch({ type: FETCH_JWT_DATA, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_JWT_LOADING });
};

export const completeJWTManagerSignup = (details) => async (dispatch) => {
    try {
        dispatch({ type: START_JWT_LOADING });
        // console.log("GOT TO ACTIONS - getUsers");
        const { data } = await api.completeJWTManagerSignup(details);
        
        dispatch({type: JWT_MANAGE_SIGN_UP, payload: data});
    } catch (error) {
        // console.log(error.message);
        dispatch({ type: JWT_MANAGE_SIGN_UP, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_JWT_LOADING });
};