import { START_LOADING_LICENCE_CSV, END_LOADING_LICENCE_CSV, FETCH_LICENCE_CSV, CLEAR_LICENCE_CHECK_CSV } from '../constants/actionTypes';

export default (state = { isLoading: true, licenceCheckCSV: [] }, action) => {
    switch (action.type) {
        case START_LOADING_LICENCE_CSV:
            return { ...state, isLoading: true };
        case END_LOADING_LICENCE_CSV:
            return { ...state, isLoading: false };;
        case FETCH_LICENCE_CSV:
            return { ...state, licenceCheckCSV: action.payload };
        case CLEAR_LICENCE_CHECK_CSV:
            return { ...state, licenceCheckCSV: []}
        default:
            return state;
    }
};