import { START_PERSONAL_VEHICLE_LOADING, END_PERSONAL_VEHICLE_LOADING, FETCH_PERSONAL_VEHICLE, CLEAR_PERSONAL_VEHICLE, DISABLE_PERSONAL_VEHICLE, DELETE_PERSONAL_VEHICLE, 
    ENABLE_PERSONAL_VEHICLE, FETCH_INSURANCE_DOCUMENT, UPDATE_INSURANCE_DOCUMENT, TEST_ACCEPT_DECLINE_VEHICLE, ADD_MILAGE_PERSONAL_COMPANY_VEHICLE, ADD_MILAGE_PERSONAL_VEHICLE, DELETE_MILAGE_PERSONAL_VEHICLE,
    DELETE_MILAGE_PERSONAL_COMPANY_VEHICLE, UPDATE_COMPANY_SERVICE_HISTORY, UPDATE_CORPORATE_VEHICLE_ASSIGNED  } from '../constants/actionTypes';


export default (state = {isLoading: true , personalVehicle: []}, action) => {
    switch (action.type) {
        case START_PERSONAL_VEHICLE_LOADING:
            return { ...state, isLoading: true };
        case END_PERSONAL_VEHICLE_LOADING:
            return { ...state, isLoading: false };
        case FETCH_PERSONAL_VEHICLE:
            return { ...state, personalVehicle: action.payload };
        case DISABLE_PERSONAL_VEHICLE:
            return { ...state, personalVehicle: action.payload };
        case DELETE_PERSONAL_VEHICLE:
            return { ...state, personalVehicle: action.payload };
        case ENABLE_PERSONAL_VEHICLE:
            return {...state, personalVehicle: action.payload };
        case FETCH_INSURANCE_DOCUMENT:
            return {...state, personalVehicle: action.payload };
        case UPDATE_INSURANCE_DOCUMENT:
            return { ...state, personalVehicle: action.payload};
        case TEST_ACCEPT_DECLINE_VEHICLE: 
            return { ...state, personalVehicle: action.payload};
        case ADD_MILAGE_PERSONAL_COMPANY_VEHICLE:
            return { ...state, personalVehicle: action.payload};
        case ADD_MILAGE_PERSONAL_VEHICLE:
            return { ...state, personalVehicle: action.payload};
        case DELETE_MILAGE_PERSONAL_VEHICLE:
            return { ...state, personalVehicle: action.payload};
        case DELETE_MILAGE_PERSONAL_COMPANY_VEHICLE:
            return { ...state, personalVehicle: action.payload};
        case UPDATE_COMPANY_SERVICE_HISTORY:
            return { ...state, personalVehicle: action.payload };
        case UPDATE_CORPORATE_VEHICLE_ASSIGNED:
            return { ...state, personalVehicle: action.payload }
        case CLEAR_PERSONAL_VEHICLE:
            return { ...state, personalVehicle: null }
        default:
            return state;
    }
}


 
