import { Typography } from '@material-ui/core';
import { TextField } from '@mui/material';
import React from 'react'

const Input = (props) => {
    let {name, label, value, variant, onChange, autoComplete='off', error=null, width=null, height=null, overflow=null, ...other} = props;


    return (
        <TextField 
        variant={variant || "outlined"}
        name={name}
        label={label}
        value={value}
        sx={{ width: width, height: height, overflowY: overflow }}
        onChange={onChange}
        // autoComplete={autoComplete}

        {...other}
        {...(error && {error:true, helperText:error})}
        />
    )
}

export default Input