import { START_LOADING_CARS_CSV, END_LOADING_CARS_CSV, FETCH_CARS_CSV, CLEAR_CARS_CSV } from '../constants/actionTypes';

export default (state = { isLoading: true, carsCSV: [] }, action) => {
    switch (action.type) {
        case START_LOADING_CARS_CSV:
            return { ...state, isLoading: true };
        case END_LOADING_CARS_CSV:
            return { ...state, isLoading: false };;
        case FETCH_CARS_CSV:
            return { ...state, carsCSV: action.payload };
        case CLEAR_CARS_CSV:
            return { ...state, carsCSV: []}
        default:
            return state;
    }
};