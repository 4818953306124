import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import React from 'react'

const Select = (props) => {
    const { name, label, value, onChange, error=null, options, disabled=false, multiSelect=false, native=false, width=null, selectNone=false, ...other } = props;

    return (
        <FormControl
        variant='outlined' {...(error && {error:true})} disabled={disabled}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
            label={label}
            name={name}
            native={native}
            multiple={multiSelect}
            value={value}
            onChange={onChange}
            sx={{ minWidth: '250px', maxWidth: '500px' }}
            MenuProps={{
                style: {zIndex: 35001}
            }}
            inputProps={native ? { id: 'select-multiple-native' } : null}
            {...other}
            >
                {   
                    selectNone ? 
                    <MenuItem disabled={!selectNone} value="">
                        <em>None</em>
                    </MenuItem>
                    :
                    null
                }
                {
                    options.map(
                        item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && (<FormHelperText>{error}</FormHelperText>)}
        </FormControl>
    )
}

export default Select