import { START_TICKET_LOADING, END_TICKET_LOADING, FETCH_ALL_TICKETS, FETCH_TICKET, CREATE_TICKET } from '../constants/actionTypes';

export default (state = { isLoading: true, tickets: [] }, action) => {
    switch (action.type) {
        case START_TICKET_LOADING:
            return {...state, isLoading: true};
        case END_TICKET_LOADING:
            return {...state, isLoading: false};
        case FETCH_ALL_TICKETS:
            return { ...state, tickets: action.payload };
        case FETCH_TICKET:
            return { ...state, tickets: action.payload };
        case CREATE_TICKET:
            return { ...state, tickets: action.payload };
        default:
            return state;
    }
};