import React, { useState } from 'react'
import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth =  ({ allowedRole }) => {
    const params = useParams()
    const { auth } = useAuth();
    const location = useLocation();
    
    let disable = false;
    return (
        <>
        {
            // User exists and if does does it have right role
            auth?.userName ? auth?.role === allowedRole || disable ?
            <Outlet context={auth} />
            :
            <Navigate to="/unauthorized" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace/>
        }
        </>                    
    );
}

export default RequireAuth;