import { useNavigate } from 'react-router-dom';
import * as api from '../api';
import { START_USER_DETAILS_LOADING, END_USER_DETAILS_LOADING, GET_USER_DETAILS, UPDATE_USER_DETAILS, UPDATE_COSTCENTRE_PERMS_DETAILS, GET_USER_LICENCE_DETAILS, CLEAR_USER_DETAILS, FIRST_LICENCE_CHECK,
    MANUAL_LICENCE_CHECK, RENEW_LICENCE_RECHECK, WITHDRAW_LICENCE_RECHECK, COMPLETE_FULL_COMPANY_SIGNUP,  } from '../constants/actionTypes';


export const getUserDetails = (userID) => async (dispatch) => {
    try {
        dispatch({ type: START_USER_DETAILS_LOADING });
        // console.log("Got to actions, getUserDetails");
        const { data } = await api.getUserDetails(userID);

        dispatch({ type: GET_USER_DETAILS , payload: data });
    } catch (error) {
        // console.log(error.message);
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });

    }
    dispatch({ type: END_USER_DETAILS_LOADING });
};

// User requests related to grey fleet
export const userRequestsGreyFleet = (auth) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })
        dispatch({ type: START_USER_DETAILS_LOADING });
        // console.log("Got to getOrders reducers");
        const { data } = await api.userRequestGreyFleet(auth);

        dispatch({ type: GET_USER_LICENCE_DETAILS , payload: data });
    } catch (error) {
        // console.log(error.message);
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_DETAILS_LOADING });
};

export const userRequestsGreyFleetRenewal = (auth) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })
        dispatch({ type: START_USER_DETAILS_LOADING });
        // console.log("Got to getOrders reducers");
        const { data } = await api.userRequestGreyFleetRenewal(auth);

        dispatch({ type: GET_USER_DETAILS , payload: data });
    } catch (error) {
        // console.log(error.message);
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_DETAILS_LOADING });

};

export const getUserLicenceDetails = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })
        dispatch({ type: START_USER_DETAILS_LOADING });
        // console.log("Got to actions, getUserDetails");
        const { data } = await api.getUserLicenceDetails(details);

        dispatch({ type: GET_USER_LICENCE_DETAILS , payload: data });
    } catch (error) {
        // console.log(error.message);
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });


    }
    dispatch({ type: END_USER_DETAILS_LOADING });
};

export const getUserPerms = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })
        dispatch({ type: START_USER_DETAILS_LOADING });
        // console.log("Got to actions, getUserPerms");
        const { data } = await api.getUserPerms(details);

        dispatch({ type: GET_USER_LICENCE_DETAILS , payload: data });
    } catch (error) {
        // console.log(error.message);
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_DETAILS_LOADING });
};


export const updateUserDetails = (userDetails) => async (dispatch) => {
    try {
        dispatch({ type: START_USER_DETAILS_LOADING });

        const { data } = await api.updateUserDetails(userDetails);


        dispatch({ type: UPDATE_USER_DETAILS, payload: data });        
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_USER_DETAILS_LOADING });

}

export const updateCostCentrePerms = (costCentreDetails) => async (dispatch) => {
    try {
        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.updateCostCentrePerms(costCentreDetails);

        dispatch({ UPDATE_COSTCENTRE_PERMS_DETAILS, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}

export const addLicenceStart = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.addLicenceStart(details);

        dispatch({ type: FIRST_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });

    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}

export const requestLicenceChangeDetails = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.requestLicenceChangeDetails(details);

        dispatch({ type: FIRST_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });

    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}

export const licenceChangeDetails = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.licenceChangeDetails(details);

        dispatch({ type: FIRST_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });

    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}

export const manualyRecheckLicence = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.manualyRecheckLicence(details);

        dispatch({ type: MANUAL_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}

export const renewLicenceCheck = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.renewLicenceCheck(details);

        dispatch({ type: MANUAL_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}

export const withdrawLicenceCheck = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.withdrawLicenceCheck(details);

        dispatch({ type: MANUAL_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}


export const completeSignupFullCompany = (details) => async (dispatch) => {
    try {
        dispatch({ type: START_USER_DETAILS_LOADING })

        dispatch({ type: CLEAR_USER_DETAILS })
        const { data } = await api.completeSignupFullCompany(details);

        dispatch({ type: COMPLETE_FULL_COMPANY_SIGNUP, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });
    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}

export const requestRecheck = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.requestRecheck(details);

        dispatch({ type: FIRST_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });

    }

    dispatch({ type: END_USER_DETAILS_LOADING })
};

export const manualyCompleteEmployeeSetup = (details) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_USER_DETAILS })

        dispatch({ type: START_USER_DETAILS_LOADING })

        const { data } = await api.manualyCompleteEmployeeSetup(details);

        dispatch({ type: FIRST_LICENCE_CHECK, payload: data })
    } catch (error) {
        // console.log(error.message)
        dispatch({ type: FIRST_LICENCE_CHECK, payload: { message: 'Failure', error: error?.response?.data } });

    }

    dispatch({ type: END_USER_DETAILS_LOADING })
}