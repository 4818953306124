import { CircularProgress } from '@mui/material';
import React, { Component, Suspense } from 'react'


const LoadPermBasedManagerUI = () => {
  const OtherComponent = React.lazy(() => import('./PermBasedManagerUI'));

  return (
    <Suspense fallback={<CircularProgress />}>
        <OtherComponent />
    </Suspense>
  )
}

export default LoadPermBasedManagerUI;