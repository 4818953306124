import { FETCH_ALL_COST_CENTRES, FETCH_COST_CENTRE, UPDATE_COST_CENTRE, DELETE_COST_CENTRE, CREATE_COST_CENTRE, START_COST_CENTRE_LOADING, END_COST_CENTRE_LOADING, CLEAR_COST_CENTRES } from '../constants/actionTypes';

const costCentreReducer = (state = { isLoading: true, costCentres: [] }, action) => {
    switch (action.type) {
        case START_COST_CENTRE_LOADING:
            return { ...state, isLoading: true };
        case END_COST_CENTRE_LOADING:
            return { ...state, isLoading: false };
        case FETCH_ALL_COST_CENTRES:
            // console.log("Got to fetch all cost centres reducer");
            return { ...state, costCentres: action.payload };
        case FETCH_COST_CENTRE:
            return { ...state, costCentres: action.payload };
        case UPDATE_COST_CENTRE:
            return { ...state, costCentres: action.payload };
        case CREATE_COST_CENTRE:
             return { ...state, costCentres: action.payload };
        case DELETE_COST_CENTRE:
            return { ...state, costCentres: action.payload };
        case CLEAR_COST_CENTRES:
            return { ...state, userDetails: null };
        default:
            return state;
    }
};

export default costCentreReducer;