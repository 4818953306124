import { FORCE_ADD_COMPANY, INVITE_COMPANY, ADD_COMPANY, START_COMPANY_ADD_LOADING, END_COMPANY_ADD_LOADING, CLEAR_ADD_COMPANY_DATA } from '../constants/actionTypes';

export default (state = {isLoading: true, addCompany: []}, action) => {
    switch (action.type) {
        case START_COMPANY_ADD_LOADING:
            return { ...state, isLoading: true };
        case END_COMPANY_ADD_LOADING:
            return { ...state, isLoading: false };
        case CLEAR_ADD_COMPANY_DATA:
            return { ...state, addCompany: null};
        default:
            return state;
    }
}